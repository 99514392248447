// @vue/component
import {ElIcon} from "element-plus";

import MineAccountInfo from "./MineAccountInfo/MineAccountInfo"
import MineActivities from "./MineActivities/MineActivities"

export default {
  name: 'MinePage',

  components: {
    ElIcon,
    MineAccountInfo,MineActivities
  },

  mixins: [],

  props: {},

  data () {
    return {
      selectedItem:"accountInfo",
      menuItems:{
        "accountInfo":{
          name:"账户信息",
          icon:`UserFilled`,
          page:`MineAccountInfo`,
        },
        "activity":{
          name:"平台活动",
          icon:`Present`,
          page:`MineActivities`,
        },
      }
    }
  },

  computed: {},

  watch: {},

  created () {

  },

  methods: {
    onSelectMenuItem(key){
      this.selectedItem = key
    }
  }
}
