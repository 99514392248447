<template>
    <div class="ChatSessionMessageList">
      <div class="fetchMore">
        <el-text v-if="hasMore" class="mx-1" type="primary" style="cursor:pointer;" @click="onFetchMore">获取更多历史消息</el-text>
        <el-text v-else class="mx-1" type="info">已经没有更多历史消息了~</el-text>
      </div>
      <ChatSessionMessage
          v-for="messageObj in messageObjList"
          :key="messageObj.timestamp"
          :messageObj="messageObj">
      </ChatSessionMessage>
    </div>
</template>

<script src="./_ChatSessionMessageList.js" lang="js"></script>
<style src="./_ChatSessionMessageList.less" lang="less" scoped></style>
