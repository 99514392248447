import IDB_Session from "@/js/repos/idb_session_class";
import IDB_Meta from "@/js/repos/idb_meta_class";
import IDB_Enums from "@/js/repos/idb_enums";
import {IDB_Chat} from "@/js/repos/idb_chat_class";
import CommonUtils from "@/js/common/utils";


const ReposSession = {

    activeSession: null,
    activeChat: null,

    async init(userInfo){
        if(!userInfo){
            return Promise.reject("Please login first!")
        }
        let uid = userInfo.uid;

        return new Promise((resolve) => {
            IDB_Meta.init().then(()=>{
                return IDB_Meta.getAsync(uid);
            }).then((metaObj)=>{
                console.log(`get metaObj of user: ${uid}`, metaObj);
                IDB_Session.init({
                    dbName: metaObj.dbName,
                    dbVersion: metaObj.dbVersion,
                    tbName: metaObj.dbName,
                }).then(()=>{
                    console.log(`IDB_Session initialized, information: ${JSON.stringify(IDB_Session.info())}`)
                    resolve();
                })
            });
        });

    },


    /**
     * 创建一个新的会话记录，并存储到数据库，返回创建好的session记录
     * @param sessionName
     * @returns {{tbName: string, dbVersion: number, name, timestamp: string, sid}}
     */
    createSessionObj(sessionName){
        let sid = CommonUtils.randomUUID()
        let sessionObj = {
            timestamp: CommonUtils.currentTimeMS(),
            sid: sid,
            name:sessionName,
            tbName: "ML#"+sid,
            dbVersion: CommonUtils.currentTimeMS(),
        }
        IDB_Session.add(sessionObj)
        return sessionObj;
    },

    /**
     * 只能修改name，必须传sid
     * @param sessionObj
     * @returns {Promise<unknown>}
     */
    async modifySession(sessionObj){
        return new Promise((resolve, reject)=>{
            IDB_Session.get(sessionObj.sid, (status, storeObj)=>{
                if(status == IDB_Enums.resultCode.success){
                    storeObj.name = sessionObj.name;
                    IDB_Session.addOrReplace(storeObj)
                    resolve("success");
                }else{
                    reject(`modify info error`, sessionObj);
                }
            })
        })
    },

    deleteSessionObj(sid){
        IDB_Session.get(sid, (status, sessionObj)=>{
            if(status == IDB_Enums.resultCode.success){
                let idb_chat = new IDB_Chat(sessionObj.tbName, sessionObj.dbVersion);
                idb_chat.destroy();
            }
        })
        IDB_Session.delete(sid);
    },

    async loadMoreSessionObjs(params){
        return new Promise((resolve, reject)=>{
            IDB_Session.getLatest(params.offset, params.limit, (status, list) => {
                if(status == IDB_Enums.resultCode.success){
                    resolve(list);
                }else{
                    reject("IDB_Session load data error");
                }
            })
        })
    },

    async switchSession(sessionObj){
        this._closeSession();
        this.activeSession = sessionObj;
        this.activeChat = new IDB_Chat(sessionObj.tbName, sessionObj.dbVersion);
        return this.activeChat.initOrOpen();
    },

    _closeSession(){
        if(!this.activeSession){
            return;
        }
        this.activeChat.closeDB();
        this.activeChat = null;
        this.activeSession = null;
    },

    saveMessageObj(messageObj){
        //IDB_Chat.add(messageObj)
        if(!this.activeChat){
            throw new Error(`Please switchSession first`);
        }
        this.activeChat.add(messageObj);
    },

    deleteMessageObj(mid){
        //IDB_Chat.delete(mid);
        if(!this.activeChat){
            throw new Error(`Please switchSession first`);
        }
        this.activeChat.delete(mid);
    },

    async countMessageObjs(){
        if(!this.activeChat){
            throw new Error(`Please switchSession first`);
        }
        let idb_chat = this.activeChat;
        return new Promise((resolve, reject)=>{
            idb_chat.count((status, count)=>{
                if(status == IDB_Enums.resultCode.success){
                    resolve(count)
                } else {
                    reject("IDB count error");
                }
            })
        });
    },
    /**
     *
     * @param params An objet like {offset, limit}
     * @returns {Promise<unknown>}
     */
    async loadMoreMessageObjs(params){
        if(!this.activeChat){
            throw new Error(`Please switchSession first`);
        }
        let idb_chat = this.activeChat;
        return new Promise((resolve, reject)=>{
            idb_chat.getLatest(params.offset, params.limit, (status, list) => {
                if(status == IDB_Enums.resultCode.success){
                    resolve(list);
                }else{
                    reject("IDB load data error");
                }
            })
        })
    }


}
export default ReposSession;