<template>
  <el-menu
      :default-active="currentActive"
      class="custom-el-menu"
      mode="horizontal"
      :ellipsis="false"
      @select="handleSelect"
      router
      active-text-color="#000000"
  >

    <el-menu-item class="custom-el-menu-item-logo hidden-sm-and-down">
      <el-image style="height: 40px; display: flex; align-items: center" :src="require('@/assets/logo.png')" />
      <div style="margin-left: 10px;">黑石AI体验馆</div>
    </el-menu-item>
    <el-menu-item v-if="isLogin" index="/chat" class="custom-el-menu-item hidden-sm-and-down">首页</el-menu-item>
    <el-menu-item v-if="isLogin" @click="openExternalLink(docsUrl)" class="custom-el-menu-item hidden-sm-and-down">
      文档站
      <el-icon :size="20">
        <Link />
      </el-icon>
    </el-menu-item>
    <el-menu-item v-if="isLogin" @click="addToBookmarks()" class="custom-el-menu-item hidden-sm-and-down">
      <el-tooltip
          class="box-item"
          effect="dark"
          content="Ctrl+D (或 Cmd+D)可快速收藏"
          placement="bottom">
        收藏
      </el-tooltip>
      <el-icon :size="20">
        <Star />
      </el-icon>
    </el-menu-item>

    <div class="flex-grow" />


    <el-menu-item v-if="isLogin" index="/chat/aichat" class="custom-el-menu-item">黑石AI助理</el-menu-item>
    <el-menu-item v-if="isLogin" index="/chat/mine" class="custom-el-menu-item">
      <el-tag v-if="isVip"
              type="warning"
              size="small" style="color:#000;"
              effect="dark" color="#FFCC33"
      >VIP</el-tag>
      <el-tag v-else
              size="small" style="color:#6f7aec;"
              color="#bcc0ef"
      >普通</el-tag>
      &nbsp;{{userInfo.nickName}}
    </el-menu-item>
    <el-menu-item v-if="isLogin" class="custom-el-menu-item">
      <el-button type="warning" link plain @click="onClickExitBtn">退出</el-button>
    </el-menu-item>

    <el-menu-item v-if="!isLogin" index="/chat" class="custom-el-menu-item hidden-sm-and-down">首页</el-menu-item>
    <el-menu-item v-if="!isLogin" @click="redirectToLink(wxLoginUrl)"
                  class="custom-el-menu-item">
      <el-button color="#626aef" :dark="true">
        微信登录
      </el-button>
    </el-menu-item>
    <el-menu-item v-if="!isLogin" @click="openExternalLink(docsUrl)" class="custom-el-menu-item hidden-sm-and-down">
      文档站
      <el-icon :size="20">
        <Link />
      </el-icon>
    </el-menu-item>
    <el-menu-item v-if="!isLogin" @click="addToBookmarks()" class="custom-el-menu-item hidden-sm-and-down">
      <el-tooltip
          class="box-item"
          effect="dark"
          content="Ctrl+D (或 Cmd+D)可快速收藏"
          placement="bottom">
        收藏
      </el-tooltip>
      <el-icon :size="20">
        <Star />
      </el-icon>
    </el-menu-item>
  </el-menu>

  <router-view></router-view>
  <el-dialog
      v-model="dialogVisible"
      title="首次登录提示"
      width="30%"
  >
    <div style="text-align:left">&nbsp;&nbsp;&nbsp;&nbsp;这是您首次登录《黑石AI体验馆》Web版，为保障您可以正常使用全平台的产品（包含小程序版），请先扫描下方二维码登录小程序版，登录成功后回到浏览器重新登录Web版:</div>
    <el-image style="height: 100px; padding-top: 20px;" :src="resUri('/resources/images/qrcode-minigram.png')" fit="cover"/>
    <template #footer>
      <span class="dialog-footer">
        <el-button type="primary" @click="dialogVisible = false">
          我知道了
        </el-button>
      </span>
    </template>
  </el-dialog>
</template>
<script src="./App.js" lang="js"></script>
<style src="./App.less" lang="less"></style>
