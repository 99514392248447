// @vue/component
import ChatSessionCard from "@/components/ChatPage/ChatSessionList/ChatSessionCard/ChatSessionCard";
import {ElButton, ElDivider, ElIcon, ElMessageBox, ElText, ElTooltip} from "element-plus";
import EventBus, {EventSet} from "@/js/common/eventbus";
import ChatManager from "@/js/service/chatmanager";
import AppConfigs from "@/js/common/appconfig";
import ReposSession from "@/js/repos/repos_session";
import CommonUtils from "@/js/common/utils";

export default {
  name: 'ChatSessionList',

  components: {
    ElButton,ElIcon,ElDivider,ElText,ElTooltip,
    ChatSessionCard
  },

  mixins: [],

  props: {},

  data () {
    return {
      sessionList:[],
      activeSessionSid: null,
      hasMore: true,
    }
  },

  computed: {

  },

  watch: {},

  created () {
    EventBus.on(EventSet.eChatSessionSwitch, this._handleSessionSwitch)
    EventBus.on(EventSet.eChatSessionDelete, this._handleChatSessionDelete)
    EventBus.on(EventSet.eChatInput, this._handleInput);
  },

  mounted() {
    this._handleFetchMoreSession(true);
  },

  methods: {

    _handleFetchMoreSession(firstFetch){
      let _this = this;
      _this.fetchMoreSessions(firstFetch).then((result)=>{
        if(_this.sessionList.length>0){
          _this.activeSession = _this.sessionList[0];
          console.log(`****** fetchSession when mounted success, now began to switch session to the first session:${_this.activeSession.sid} ******`)
          EventBus.emit(EventSet.eChatSessionSwitch, {sid: _this.activeSession.sid})
        }
        if(result.length < AppConfigs.fetchSessionLimit){
          _this.hasMore = false;
        }
        _this.scrollSessionListToBottom();
      });
    },

    onFetchMoreSession(){
      this._handleFetchMoreSession(false);
    },

    _handleInput({text}){
      let name = this.activeSession.name;
      if(!name.startsWith(AppConfigs.defaultSessionName)){
        return;
      }
      this.activeSession.name = text.substring(0, text.length>30?30:text.length);
      ReposSession.modifySession(this.activeSession).then(()=>{
        console.log("modify name success", text)
      });
    },

    _handleSessionSwitch({sid}){
      for(let i=0;i<this.sessionList.length; i++){
        if(sid == this.sessionList[i].sid){
          this.activeSession = this.sessionList[i];
        }
      }
    },
    _handleChatSessionDelete({sid}){
      let _this = this;
      let __confirmDelete = function(){
        //删除展示的组件
        for(let i=0; i< _this.sessionList.length; i++){
          if(sid == _this.sessionList[i].sid){
            _this.sessionList.splice(i, 1);
            break;
          }
        }
        //如果删除的是当前活跃会话，尝试切换到第一个会话
        if(sid == _this.activeSession.sid){
          if(_this.sessionList.length>0){
            _this.activeSession = _this.sessionList[0];
            console.log(`****** reset, switch session to the first session:${_this.activeSession.sid} ******`)
            EventBus.emit(EventSet.eChatSessionSwitch, {sid: _this.activeSession.sid})
          }
        }

        //删除存储
        ChatManager.handleDeleteSession(sid);
      }

      ElMessageBox.confirm(
          '确认删除本会话吗，删除后不可找回?', '删除提示框',
          {
            confirmButtonText: '确认',
            cancelButtonText: '取消',
            type: 'error',
          })
          .then(() => __confirmDelete())
          .catch(reason => console.debug(reason))
    },

    onClickNewSession(){
      let _this = this;
      ChatManager.createNewSession().then((sessionObj)=>{
        _this.sessionList.unshift(sessionObj);
        _this.activeSession = sessionObj;
        console.log(`****** create new Sesion success, now began to switch session to:${_this.activeSession.sid} ******`, sessionObj)
        //等待100ms，让sessionObj在页面中渲染出来
        setTimeout(()=>{
          EventBus.emit(EventSet.eChatSessionSwitch, {sid: _this.activeSession.sid})
        },100)
      });
    },

    async fetchMoreSessions(firstFetch){
      let _this = this;
      return new Promise((resolve, reject) => {
        ChatManager.fetchMoreSessions({
          offset: this.sessionList.length,
          limit: AppConfigs.fetchSessionLimit
        }).then((sessionList)=>{
          if(firstFetch && sessionList.length == 0) {
            let newSession = ReposSession.createSessionObj(AppConfigs.defaultSessionName+"#"+CommonUtils.random(1,10000));
            sessionList.push(newSession);
            console.log("this is first fetch, create a new one")
          }
          _this.sessionList = _this.sessionList.concat(sessionList);
          resolve(sessionList);
        }).catch((error)=>{
          EventBus.emit(EventSet.eEleMessageTip, {
            message: error,
            type: "warning"
          })
          reject();
        });
      })
    },

    onClickBackToTop(){
      this.scrollSessionListToTop();
    },
    scrollSessionListToBottom(){
      setTimeout(()=>{
        let content = this.$refs.scrollChatSessionList;
        if(!content){
          return;
        }
        content.scrollTop = content.scrollHeight - content.clientHeight;
        //console.log("scroll to bottom", content.scrollTop, content.scrollHeight, content.clientHeight)
      }, 50)
    },
    scrollSessionListToTop(){
      let content = this.$refs.scrollChatSessionList;
      content.scrollTop = 0;
    },
  }
}
