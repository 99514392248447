const LsAuth = {
    storeKey: "IBS_AI_Auth",

    struct:{
        loginToken : null
    },

    store({loginToken}){
        this.struct = {
            loginToken: loginToken
        }
        window.localStorage.setItem(this.storeKey, JSON.stringify(this.struct));
        console.debug(`localstorage store ${this.storeKey} successfully`)
    },

    get(){
        let storeValue = window.localStorage.getItem(this.storeKey);
        console.debug(`localstorage get ${this.storeKey} successfully`)
        if(!storeValue){
            return this.struct;
        }
        this.struct = JSON.parse(storeValue)
        return this.struct;
    },

    clear(){
        window.localStorage.removeItem(this.storeKey);
        this.struct = {
            loginToken: null,
        }
        console.debug(`localstorage clear ${this.storeKey} successfully`)
    }

}

export default LsAuth;