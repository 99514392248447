import CommonUtils from "@/js/common/utils";

const AppConfigs = {
    version: "web-v0.0.1",
    serverHost: "https://ibstech.cn/hk",
    resHost:"https://ibstech.cn",

    webHost:"https://ibstech.cn",
    wxLoginUrl: "https://open.weixin.qq.com/connect/qrconnect?appid=wxa26c78999939629a&redirect_uri=https://ibstech.cn/oauth2/callback&response_type=code&scope=snsapi_login&state=w#wechat_redirect",

    fetchLimit: 10,
    fetchSessionLimit: 15,
    platform: "PC-Web",

    defaultSessionName: "#新会话",

    chatConstants: {
        waitingText: "正在思考，请稍后",

        needWaitingText : (extInfo)=>{
            console.log("extInfo",extInfo)
            let histroyQuestions = extInfo.historyQuestions;
            let nextTime = extInfo.nextQuestionTime;
            let nextTimeText = CommonUtils.formatSeconds(nextTime)

            return `已累积为您解答了${histroyQuestions}个问题，由于资源有限，普通版用户需要排队(${nextTimeText}后可继续提问)。\n\n如果您不想排队，欢迎您 **购买VIP纯享版**（点击左上角工具条‘小黄车’黄色按钮）`
        },
        unDefinedErrText : (reason)=>`未知错误，您可以向我们反馈，错误信息：${reason}`,

        answeringTipText : () => `AI回答还未结束，请先等待`,
        inputEmptyTipText : () => `不可以输入空字符串哦~`,

        copyCodeTextSuccess: ()=>`代码拷贝成功`,
        copyCodeTextFailed: ()=>`代码拷贝失败`,

        copyTextSuccess: ()=>`内容拷贝成功`,
        copyTextFailed: ()=>`内容拷贝失败`,

        copyUnavailable: ()=>`剪贴板不可用`,
    },
}

export default AppConfigs;
