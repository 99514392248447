// @vue/component

import {
  ElAvatar, ElIcon, ElTooltip, ElPopover, ElImage, ElButton, ElMessageBox
} from "element-plus"

import EventBus, {EventSet} from "@/js/common/eventbus";
import router, {routerPages} from "@/router";
import AppConfigs from "@/js/common/appconfig";

export default {
  name: 'ChatFloatTools',

  components: {
    ElAvatar, ElIcon, ElTooltip,ElPopover,ElImage,ElButton
  },

  mixins: [],

  props: {},

  data () {
    return {}
  },

  computed: {},

  watch: {},

  created () {},

  methods: {
    resUri(uri){
      return AppConfigs.resHost + uri;
    },
    onDownloadAD(){
      const imageUrl = AppConfigs.resHost + '/resources/images/share-ad.jpg';
      const downloadLink = document.createElement('a');
      downloadLink.href = imageUrl;
      downloadLink.download = 'example.jpg';
      downloadLink.style.display = 'none';
      downloadLink.target="_blank";
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    },
    onClickService(){

    },
    onClickShare(){

    },
    onClickMagicStick(){
      EventBus.emit(EventSet.eChatToolsClickPrompt, {})
    },
    onClickShoppingCart(){
      ElMessageBox.confirm(
          '本次操作将会跳转到会员充值页面，是否继续?',
          '跳转提示框',
          {
            confirmButtonText: '确认',
            cancelButtonText: '取消',
            type: 'warning',
          }
      )
          .then(() => {
            router.replace(routerPages.MinePage)
          })

    },
  }
}
