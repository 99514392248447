import {v1 as uuidv1} from "uuid"

const CommonUtils = {
    random(min, max){
        return Math.floor(Math.random() * (max - min + 1) ) + min;
    },
    randomId(){
        return this.random(100000000, 999999999);
    },
    formatSeconds(seconds){
        let date = new Date(seconds*1000);
        return this.formatTime(date)
    },
    formatTime(date){
        let formatNumber = (n)=>{
            const s = n.toString()
            return s[1] ? s : '0' + s
        }
        const year = date.getFullYear()
        const month = date.getMonth() + 1
        const day = date.getDate()
        const hour = date.getHours()
        const minute = date.getMinutes()
        const second = date.getSeconds()

        return (
            [year, month, day].map(formatNumber).join('/') +
            ' ' +
            [hour, minute, second].map(formatNumber).join(':')
        )
    },
    now(){
        return this.formatTime(new Date())
    },
    currentTimeMS(){
        return new Date().getTime();
    },
    timestamp(date){
        return date.getTime();
    },
    randomUUID(){
        return uuidv1();
    },
}

export default CommonUtils;