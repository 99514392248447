import requests from "@/js/remote/requests";

const RpcChat = {
    async personalInfo(){
        try{
            let uri = "/api/v1/personalInfo";
            return await requests.get(uri);
        } catch (reason){
            return Promise.reject(reason)
        }
    },
    async modifyPersonalInfo(params){
        try{
            let uri = "/api/v1/modifyPersonalInfo";
            return await requests.post(uri, params);
        } catch (reason){
            return Promise.reject(reason)
        }
    },

    async activeVipBySt(params){
        try{
            let uri = "/api/v1/activeVipByST";
            return await requests.post(uri, params);
        } catch (reason){
            return Promise.reject(reason)
        }
    },

    async activeVipCode(params){
        try{
            let uri = "/api/v1/activeVipcode";
            return await requests.post(uri, params);
        } catch (reason){
            return Promise.reject(reason)
        }
    },

    async vipHistory(params){
        try{
            let uri = "/api/v1/vipHistory";
            return await requests.get(uri, params);
        } catch (reason){
            return Promise.reject(reason)
        }
    },

    async generateVipCode(params){
        try{
            let uri = "/api/v1/vipcode";
            return await requests.post(uri, params);
        } catch (reason){
            return Promise.reject(reason)
        }
    },

    async sendQuestion(params){
        try{
            let uri = "/api/v2/question";
            return await requests.post(uri, params);
        } catch (reason){
            return Promise.reject(reason)
        }
    },
    async messagePolling(params){
        try{
            let uri = "/api/v2/messages";
            return await requests.post(uri, params);
        } catch (reason){
            return Promise.reject(reason)
        }
    },
    async login(params){
        try{
            let uri = "/oauth2/callback";
            return await requests.get(uri, params);
        } catch (reason){
            return Promise.reject(reason)
        }
    }

}

export default RpcChat;