import IDB_Enums from "@/js/repos/idb_enums";

export class IDB_ChatSession{

    _upgradeneeded(event){
        const db = event.target.result;
        const ChatSessionStore = db.createObjectStore(this.tbName, { keyPath: 'timestamp' });

        ChatSessionStore.createIndex('sid', 'sid', { unique: true });

        console.log(`table ${this.tbName} create success!`);
    }
    info(){
        return {
            dbName: this.dbName,
            tbName: this.tbName,
            dbVersion: this.dbVersion,
            inited: !!this.db
        }
    }

    _checkDB(){
        if(!this.db){
            throw new Error("Please invoke init first");
        }
    }

    async init({dbName, dbVersion, tbName}){
        if(this.db){
            console.log("IDB_ChatSession has been created!", this.db)
            return Promise.reject("IDB_ChatSession has been created!");
        }

        this.dbName = dbName;
        this.tbName = tbName;
        this.dbVersion = dbVersion;

        let _this = this;
        return new Promise((resolve, reject)=>{
            const openRequest = indexedDB.open(_this.dbName, _this.dbVersion);
            openRequest.onupgradeneeded = function(event) {
                // 指定需要存储的数据结构，例如对象仓库
                _this._upgradeneeded(event);
            };
            openRequest.onsuccess = function(event) {
                _this.db = event.target.result;
                if(_this.db){
                    console.log(`table ${_this.tbName} open success!`);
                }
                resolve("success");
            };
            openRequest.onerror = function (error) {
                reject(error);
            }
        });

    }
    destroy(){
        if(this.db){
            this.db.deleteObjectStore(this.tbName);
            this.db.close();
            this.db = null;
            console.log(`table ${this.tbName} delete success! `);
        }
    }
    count(cb_receiver){
        this._checkDB();

        let db = this.db;
        const transaction = db.transaction([this.tbName], 'readonly');
        const objectStore = transaction.objectStore(this.tbName);

        const req = objectStore.count();
        req.onsuccess = function(event) {
            const count = event.target.result;
            cb_receiver(IDB_Enums.resultCode.success, count);
        };
    }

    add(sessionObj){
        this._checkDB();

        let db = this.db;

        const transaction = db.transaction([this.tbName], 'readwrite');
        const objectStore = transaction.objectStore(this.tbName);
        const request = objectStore.add(sessionObj);

        request.onsuccess = function(event) {
            console.debug('数据已保存到对象仓库中', event);
            transaction.commit();
        };
        request.onerror = function(error) {
            console.error('数据存储到对象仓库中失败', error);
            transaction.abort();
        }
    }

    addOrReplace(sessionObj){
        this._checkDB();

        let db = this.db;

        const transaction = db.transaction([this.tbName], 'readwrite');
        const objectStore = transaction.objectStore(this.tbName);
        const request = objectStore.put(sessionObj);

        request.onsuccess = function(event) {
            console.debug('数据已保存到对象仓库中', event);
            transaction.commit();
        };
        request.onerror = function(error) {
            console.error('数据存储到对象仓库中失败', error);
            transaction.abort();
        }
    }

    /**
     * 从倒数offset开始，获取最近的limit条记录
     * @param offset 倒数第几个，从0开始。
     * @param limit 获取的记录个数
     * @param db_receiver 最后以(IDB_Enums.resultCode, [sessionObj]|errEvent)结果返回
     */
    getLatest(offset, limit, db_receiver){
        this._checkDB();

        let db = this.db;
        const transaction = db.transaction(this.tbName);
        const store = transaction.objectStore(this.tbName);

        const req = store.openCursor(null, "prev");

        let results = [];
        let cnt = 0;

        req.onsuccess = function(event) {
            const cursor = event.target.result;
            if(!cursor){
                console.debug(`返回 ${results.length} 条存储记录: `, results);
                db_receiver(IDB_Enums.resultCode.success, results);
                return;
            }
            if(cnt>=offset){
                results.push(cursor.value);
                if(results.length>=limit){
                    console.debug(`返回 ${results.length} 条存储记录: `, results);
                    db_receiver(IDB_Enums.resultCode.success, results);
                    return;
                }
            }
            cursor.continue();
            cnt += 1;
        };

        req.onerror = function(event) {
            console.error(`检索存储记录时出错: `, event);
            db_receiver(IDB_Enums.resultCode.error, event);
        };

    }

    get(sid, cb_receiver){
        this._checkDB();

        let db = this.db;

        const transaction = db.transaction([this.tbName], 'readonly');
        const store = transaction.objectStore(this.tbName);
        const index = store.index('sid');

        const req = index.get(sid);

        req.onsuccess = function(event) {
            const result = event.target.result;

            if (result) {
                console.debug(`通过sid[${sid}]检索到存储记录[,]: `, result);
                cb_receiver(IDB_Enums.resultCode.success, result);
            } else {
                console.debug(`没有找到sid[${sid}]的存储记录`);
                cb_receiver(IDB_Enums.resultCode.notfound, null);
            }
        };

        req.onerror = function(event) {
            console.error(`检索sid[${sid}]存储记录时出错: `, event);
            cb_receiver(IDB_Enums.resultCode.error, event);
        };
    }

    delete(sid){
        this._checkDB();

        let db = this.db;

        const transaction = db.transaction([this.tbName], 'readwrite');
        const objectStore = transaction.objectStore(this.tbName);

        const index = objectStore.index("sid");
        const request = index.get(sid);

        request.onsuccess = function(event) {
            const session = event.target.result;
            objectStore.delete(session.timestamp);
            console.debug(`成功删除 sid 为 ${sid} 的消息记录`);
            transaction.commit();
        };

        request.onerror = function(event) {
            console.error(`删除 sid 为 ${sid} 的消息记录失败, ${event}`);
            transaction.abort();
        };

    }
}
/**
 * 版本1的数据表定义：<br/>
 *     <pre>
 *         sessionObj: {
 *             timestamp: "327933309349", //时间戳，主键
 *             sid: "#2343", //会话id，唯一键
 *             name: "会话", // 会话名，可修改
 *             tbName: "ML#3243" // 关联的表名称
 *         }
 *     </pre>
 *
 */
const idbSession = new IDB_ChatSession()
//idbSession.init();

export default idbSession;
