<template>
    <div class="ChatSessionMessage">
      <div v-if="messageObj.chattype == 'send'" class="ChatSendBlock AutoMediaWidth">
        <div class="ChatSendMain">
          <div class="ChatSendTitle">
            {{ messageObj.nickname }} {{ messageObj.datetime }}
          </div>
          <div :class="'ChatSendContent'+ (isVip?' ChatSendVipBackground':'')">
            {{ messageObj.message }}
          </div>
          <div class="ChatSendToolbar">
            <el-tooltip
                class="box-item"
                effect="dark"
                content="重新发送问题"
                placement="top-start"
            >
              <el-icon class="ChatSendToolbarIcon" @click="onClickResend"><RefreshLeft /></el-icon>
            </el-tooltip>
            <el-tooltip
                class="box-item"
                effect="dark"
                content="拷贝全部内容到剪贴板"
                placement="top-start"
            >
              <el-icon class="ChatSendToolbarIcon" @click="onClickCopy"><DocumentCopy /></el-icon>
            </el-tooltip>
            <el-tooltip
                class="box-item"
                effect="dark"
                content="删除本条历史消息（不可恢复）"
                placement="top-start"
            >
              <el-icon class="ChatSendToolbarIcon" @click="onClickDelete"><Delete /></el-icon>
            </el-tooltip>
          </div>
        </div>
        <div class="ChatSendAvatar">
          <el-avatar :size="50">
            <el-icon :size="30"><User/></el-icon>
          </el-avatar>
        </div>
      </div>
      <div v-else-if="messageObj.chattype == 'receive'" class="ChatReceiveBlock AutoMediaWidth">
        <div class="ChatReceiveAvatar">
          <el-avatar :size="50" :src="messageObj.avatar">
            <el-icon :size="30"><Platform/></el-icon>
          </el-avatar>
        </div>
        <div class="ChatReceiveMain">
          <div class="ChatReceiveTitle">
            {{ messageObj.nickname }} {{ messageObj.datetime }}
          </div>
          <div class="ChatReceiveContent" v-html="markdownToHtml(messageObj.message)">


          </div>
          <div class="ChatReceiveToolbar">
            <el-tooltip
                class="box-item"
                effect="dark"
                content="拷贝全部内容到剪贴板"
                placement="top-start"
            >
              <el-icon class="ChatReceiveToolbarIcon" @click="onClickCopy"><DocumentCopy /></el-icon>
            </el-tooltip>
            <el-tooltip
                class="box-item"
                effect="dark"
                content="删除本条历史消息（不可恢复）"
                placement="top-start"
            >
              <el-icon class="ChatReceiveToolbarIcon" @click="onClickDelete"><Delete /></el-icon>
            </el-tooltip>
          </div>
        </div>

      </div>
      <div v-else>
        other {{JSON.stringify(messageObj)}}
      </div>

    </div>
</template>

<script src="./_ChatSessionMessage.js" lang="js"></script>
<style src="./_ChatSessionMessage.less" lang="less" scoped></style>
