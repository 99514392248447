<template>
    <div class="StDetail">
      <div class="StBalance" style="min-width: 30%">
        <div class="StBalanceTitle">账户余额</div>
        <div class="StBalanceContent">
          <el-tag size="large" style="color:#ffffff; font-size: 16px;"
                  color="#7232dd"
          >$ST</el-tag>
          {{ userInfo.profile.balance }}
        </div>
      </div>
      <div class="StBalance" style="flex:1;">
        <div class="StBalanceTitle">石币明细</div>
        <div class="StHistoryContent">
          还没有任何明细哦~
        </div>
      </div>
    </div>
    <div class="StBlock">
    <div class="StBlockTitle">
      帮助
    </div>
    <div>
      <div class="info-text-line">
        1、什么是石币？<br/>官方虚拟代币，符号为'ST$'，仅限于平台内消费。目前支持的使用场景：兑换VIP纯享版
      </div>
      <div class="info-text-line">
        2、如何获取？<br/>参加官方的活动可以赚取大量石币。注意，Web版活动还未开放，请扫描下方二维码进入小程序参与活动。<br/>
      </div>
      <div class="info-text-line">
        <el-image style="height: 100px;" :src="resUri('/resources/images/qrcode-minigram.png')" fit="cover"/>
      </div>

    </div>
  </div>
</template>

<script src="./_StDetail.js" lang="js"></script>
<style src="./_StDetail.less" lang="less" scoped></style>
