// @vue/component
import {ElIcon} from "element-plus";
import AppConfigs from "@/js/common/appconfig";
import EventBus, {EventSet} from "@/js/common/eventbus";

export default {
  name: 'ChatHelper',

  components: {
    ElIcon
  },

  mixins: [],

  props: {},

  data () {
    return {
      show: true,
      helpItems:{
        "item-1":{title:"入门引导",prompt:"我希望你扮演我的ChatGPT入门引导员，因为我完全没有使用过AI。你将通过一些启发式的提示词示例向我演示和解释如何正确地向AI提问。"},
        "item-2":{title:"英语翻译与改进",prompt:"我希望你能担任英语翻译、拼写校对和修辞改进的角色。我会用任何语言和你交流，你会识别语言，将其翻译并用更为优美和精炼的英语回答我。请将我简单的词汇和句子替换成更为优美和高雅的表达方式，确保意思不变，但使其更具文学性。请仅回答更正和改进的部分，不要写解释。我的第一句话是“how are you ?”，请翻译它。" },
        "item-3":{title:"AI写作导师",prompt:"我想让你做一个 AI 写作导师。我将为您提供一名需要帮助改进其写作的学生，您的任务是使用人工智能工具（例如自然语言处理）向学生提供有关如何改进其作文的反馈。您还应该利用您在有效写作技巧方面的修辞知识和经验来建议学生可以更好地以书面形式表达他们的想法和想法的方法。我的第一个请求是“我需要有人帮我修改我的硕士论文”。"},
        "item-4":{title:"UX/UI 开发人员",prompt:"我希望你担任 UX/UI 开发人员。我将提供有关应用程序、网站或其他数字产品设计的一些细节，而你的工作就是想出创造性的方法来改善其用户体验。这可能涉及创建原型设计原型、测试不同的设计并提供有关最佳效果的反馈。我的第一个请求是“我需要帮助为我的新移动应用程序设计一个直观的导航系统。”"},
        "item-5":{title:"代码审查与教学",prompt:"Javascript如何使用Promise，请给我一些示例代码辅助说明"},
        "item-6":{title:"全栈开发工程师",prompt:"我想让你充当软件开发人员。我将提供一些关于 Web 应用程序要求的具体信息，您的工作是提出用于使用 Golang 和 Angular 开发安全应用程序的架构和代码。我的第一个要求是'我想要一个允许用户根据他们的角色注册和保存他们的车辆信息的系统，并且会有管理员，用户和公司角色。我希望系统使用 JWT 来确保安全。"},
        "item-7":{title:"提示词生成器",prompt:"我希望你充当提示生成器。首先，我会给你一个这样的标题：《做个英语发音帮手》。然后你给我一个这样的提示：“我想让你做土耳其语人的英语发音助手，我写你的句子，你只回答他们的发音，其他什么都不做。回复不能是翻译我的句子，但只有发音。发音应使用土耳其语拉丁字母作为语音。不要在回复中写解释。我的第一句话是“伊斯坦布尔的天气怎么样？”。（你应该根据我给的标题改编示例提示。提示应该是不言自明的并且适合标题，不要参考我给你的例子。）我的第一个标题是“充当代码审查助手”"},
        "item-8":{title:"塔罗占卜师",prompt:"我请求你担任塔罗占卜师的角色。 您将接受我的问题并使用虚拟塔罗牌进行塔罗牌阅读。 不要忘记洗牌并介绍您在本套牌中使用的套牌。 问我给3个号要不要自己抽牌？ 如果没有，请帮我抽随机卡。 拿到卡片后，请您仔细说明它们的意义，解释哪张卡片属于未来或现在或过去，结合我的问题来解释它们，并给我有用的建议或我现在应该做的事情 . 我的问题是“我的财务状况如何？”"},
      }
    }
  },

  computed: {},

  watch: {},

  created () {
    EventBus.on(EventSet.eChatMessageListLengthChanged, this.onLengthChange)
    EventBus.on(EventSet.eChatToolsClickPrompt, this.switchShowPrompt)
  },

  methods: {
    switchShowPrompt(){
      this.show = !this.show;
    },
    onLengthChange({newVal}){
      if(newVal > 0){
        this.show = false;
      }else{
        this.show = true;
      }
    },
    onClickHide(){
      this.show = false;
    },
    onClickMore(){
      let url = AppConfigs.webHost+ "/docs/#/ChatGPT/prompts?id=prompt调教示例";
      window.open(url);
    },
    onClickHelperItem(key, item){
      console.debug("click", key, item.title, item.prompt)
      EventBus.emit(EventSet.eChatInputPrompt, {prompt: item.prompt})
    }
  }
}
