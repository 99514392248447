// @vue/component

import ChatSessionMessage from "./ChatSessionMessage/ChatSessionMessage"
import AppConfigs from "@/js/common/appconfig";
import EventBus,{EventSet} from "@/js/common/eventbus";

import {
  ElMessageBox,
  ElText
} from "element-plus"
import ChatManager from "@/js/service/chatmanager";

export default {
  name: 'ChatSessionMessageList',

  components: {
    ChatSessionMessage, ElText
  },

  mixins: [],

  props: {
  },

  data () {
    return {
      messageObjList:[],
      hasMore: true,
      currentSession: null,
    }
  },

  computed: {},

  watch: {
    'messageObjList.length':function (newVal, oldVal){
      EventBus.emit(EventSet.eChatMessageListLengthChanged, {newVal, oldVal})
    }
  },

  created () {
    EventBus.on(EventSet.eChatMessageShow, this.onChatMessageShow)
    EventBus.on(EventSet.eChatAnswerPolling, this.onChatAnswerPolling);
    EventBus.on(EventSet.eChatMessageAction, this.onChatMessageActions);

    EventBus.on(EventSet.eChatSessionSwitchReady, this.onSessionSwitchReady)

    //this.fetchMoreMessages("bottom");

  },

  methods: {
    // eslint-disable-next-line no-unused-vars
    onFetchMore(_){
      this.fetchMoreMessages("top");
    },
    onSessionSwitchReady({sessionObj}){
      this.currentSession = sessionObj;
      this.hasMore = true;
      this.messageObjList = [];
      this.fetchMoreMessages("bottom");
    },

    onChatAnswerPolling(payload) {
      let type = payload.type;
      let data = payload.data;
      let message = payload.message;

      let enums = EventSet.eChatAnswerPollingType;
      let waitingMessageObj = this.messageObjList[this.messageObjList.length-1];

      if (type == enums.start) {
        console.log("start event")
        return;
      }
      if (type == enums.sent) {
        console.log("sent event", data)
        waitingMessageObj.message = "";
        return;
      }
      if (type == enums.polling) {
        console.log("polling event", data)
        waitingMessageObj.message += data.data.message;
        EventBus.emit(EventSet.eChatContentScrollToBottom);
        return;
      }
      if (type == enums.over) {
        console.log("over event", data);
        waitingMessageObj.message += data.data.message;
        EventBus.emit(EventSet.eChatContentScrollToBottom);

        let overHook = payload.overHook;
        if(overHook){
          overHook(waitingMessageObj);
        }
        return;
      }
      if (type == enums.sentFailed){
        console.log("sentFailed event", message);
        let overHook = payload.overHook;
        waitingMessageObj.message = payload.message;
        if(overHook){
          overHook(waitingMessageObj);
        }
        return;
      }
      if (type == enums.pollingFailed){
        console.log("pollingFailed event", message);
        let overHook = payload.overHook;
        waitingMessageObj.message = payload.message;
        if(overHook){
          overHook(waitingMessageObj);
        }
        return;
      }
    },
    onChatMessageActions({type,messageObj}){
      if(type == EventSet.eChatMessageActionType.delete){
        let _this = this;
        let __confirmDelete = ()=>{
          let mid = messageObj.mid;
          for(let i=0; i<_this.messageObjList.length; i++){
            let msg = _this.messageObjList[i];
            if(msg.mid == mid){
              this.messageObjList.splice(i, 1);
              EventBus.emit(EventSet.eChatMessageDelete, mid)
              console.log(`remove msg:${mid}`, messageObj)
              return;
            }
          }
        };
        ElMessageBox.confirm(
            '确认删除本条消息吗?', '删除提示框',
            {
              confirmButtonText: '确认',
              cancelButtonText: '取消',
              type: 'warning',
            })
            .then(() => __confirmDelete())
            .catch(reason => console.debug(reason))
      }
    },
    fetchMoreMessages(scrollType){
      let _this = this;
      let __cbFetchMoreMessages = function(messageList){
        if(!messageList || messageList.length == 0){
          _this.hasMore = false;
          return;
        }
        for(let i=0; i<messageList.length; i++){
          _this.messageObjList.unshift(messageList[i]);
        }
        if(messageList.length<AppConfigs.fetchLimit){
          _this.hasMore = false;
        }
        console.log(`after fetch ${messageList.length} msgs(total=${_this.messageObjList.length}):`, _this.messageObjList)
        if(scrollType){
          if(scrollType == "bottom"){
            EventBus.emit(EventSet.eChatContentScrollToBottom)
          }else if(scrollType == "top"){
            EventBus.emit(EventSet.eChatContentScrollToTop)
          }
        }
      };
      ChatManager.fetchMoreMessageObjs({
        offset: this.messageObjList.length,
        limit: AppConfigs.fetchLimit
      }).then((messageObjList)=>{
        __cbFetchMoreMessages(messageObjList);
      })
    },

    onChatMessageShow(params){
      //{messageObj: displayMessageObj}
      let messageObj = params.messageObj;
      this.messageObjList.push(messageObj);

      EventBus.emit(EventSet.eChatContentScrollToBottom)
      console.log("messagelist get message", messageObj)
    },
  }
}
