import EventBus, {EventSet} from "@/js/common/eventbus";
import CommonUtils from "@/js/common/utils";
import ReposAuth from "@/js/repos/repos_auth";
import AppConfigs from "@/js/common/appconfig";
import RpcChat from "@/js/remote/rpc_chat";
import ReposSession from "@/js/repos/repos_session";

const ChatManager = {
    currentSessionSid: null,
    reset: false,

    init(){
        //ReposSession.init();

        let _this = this;

        EventBus.on(EventSet.eChatMessageDelete, (mid)=>{
            _this._handleDeleteMessage(mid);
        });

        EventBus.on(EventSet.eChatSessionSwitch, (sid)=>{
            _this._resetSessionNextQuestion();
            _this.currentSessionSid = sid;
        })
    },

    _resetSessionNextQuestion(){
      this.reset = true;
    },

    async fetchMoreSessions({offset, limit}){
        return new Promise((resolve, reject)=>{
            ReposSession.loadMoreSessionObjs({offset, limit}).then((sessionList)=>{
                resolve(sessionList);
            }).catch((error)=>{
                reject(error);
            });
        })
    },
    async fetchMoreMessageObjs({offset, limit}){
        let _this = this;
        return new Promise((resolve, reject)=>{
            let ret = ReposSession.loadMoreMessageObjs({
                offset: offset,
                limit: limit
            });
            ret.then((messageObjList)=>{
                for(let i =0; i< messageObjList.length; i++){
                    messageObjList[i] = _this._buildDisplayMessageObj(messageObjList[i]);
                }
                resolve(messageObjList);
            }).catch(err=>{
                reject(err);
            });
        });
    },
    handleInput(input){
        if(!input || !input.text || input.text.trim().length == 0){
            return;
        }
        let _this = this;
        let text = input.text.trim();
        if(input.reset){
            _this._resetSessionNextQuestion();
        }

        let questionMessageObj = _this._buildMainMessageObj(text, "send")
        this._storeMessageObj(questionMessageObj);

        let displayMessageObj = _this._buildDisplayMessageObj(questionMessageObj);
        EventBus.emit(EventSet.eChatMessageShow, {messageObj: displayMessageObj})

        let waitingMessageObj = _this._buildMainMessageObj(AppConfigs.chatConstants.waitingText, "receive")
        //处理可能的主键冲突，timestamp太快
        while(waitingMessageObj.timestamp == questionMessageObj.timestamp){
            waitingMessageObj = _this._buildMainMessageObj(AppConfigs.chatConstants.waitingText, "receive")
        }
        displayMessageObj = _this._buildDisplayMessageObj(waitingMessageObj);
        EventBus.emit(EventSet.eChatMessageShow, {messageObj: displayMessageObj})

        _this._answeringStatusChange({type: EventSet.eChatAnswerPollingType.start});
        _this._startAnswering(questionMessageObj);
        return;
    },

    handleDeleteSession(sid){
        ReposSession.deleteSessionObj(sid);
    },

    async createNewSession(){
      return new Promise((resolve) =>{
         let sessionObj = ReposSession.createSessionObj(AppConfigs.defaultSessionName+"#"+CommonUtils.random(1,10000));
         resolve(sessionObj);
      });
    },

    _handleDeleteMessage(mid){
        ReposSession.deleteMessageObj(mid);
    },

    _answeringStatusChange(payload){
        EventBus.emit(EventSet.eChatAnswerPolling, payload);
    },

    _answeringOverHook(commitedMessageObj, _this){
        console.log("commit messageObj", commitedMessageObj)
        _this._storeMessageObj(commitedMessageObj);
    },
    _startPolling(){
        let ret = RpcChat.messagePolling({});
        let _this = this;

        ret.then((data)=>{
            let code = data.code;
            if(code == 200){
                let hasMore = data.data.more;
                if(hasMore){
                    _this._answeringStatusChange({
                        type: EventSet.eChatAnswerPollingType.polling,
                        data: data
                    });
                    _this._startPolling();
                }else{
                    _this._answeringStatusChange({
                        type: EventSet.eChatAnswerPollingType.over,
                        data: data,
                        overHook: (commitedMessage)=>{_this._answeringOverHook(commitedMessage, _this)}
                    });
                }
            }else {
                _this._answeringStatusChange({
                    type: EventSet.eChatAnswerPollingType.pollingFailed,
                    message: data.message,
                    overHook: (commitedMessage)=>{_this._answeringOverHook(commitedMessage, _this)}
                });
            }
        }).catch((reason)=>{
            _this._answeringStatusChange({
                type: EventSet.eChatAnswerPollingType.pollingFailed,
                message: AppConfigs.chatConstants.unDefinedErrText(reason),
                overHook: (commitedMessage)=>{_this._answeringOverHook(commitedMessage, _this)}
            });
        });
    },

    _startAnswering(questionMessageObj){
        let _this = this;

        let question = questionMessageObj.message;
        let params = {
            question: question,
            platform: AppConfigs.platform,
            adSeen: false,
            resetSession: _this.reset, // from input
        };
        _this.reset = false;

        let ret = RpcChat.sendQuestion(params);

        ret.then((data)=>{
            let code = data.code;
            if(code == 200){
                let more = data.data.more;
                if(!more){
                    let extInfo = data.data.extInfo;
                    if(extInfo && extInfo.needAdvertise){
                        _this._answeringStatusChange({
                            type: EventSet.eChatAnswerPollingType.sentFailed,
                            message: AppConfigs.chatConstants.needWaitingText(extInfo),
                            overHook: (commitedMessage)=>{_this._answeringOverHook(commitedMessage, _this)}
                        });
                    }else{
                        _this._answeringStatusChange({
                            type: EventSet.eChatAnswerPollingType.sentFailed,
                            message: data.data.message,
                            overHook: (commitedMessage)=>{_this._answeringOverHook(commitedMessage, _this)}
                        });
                    }
                    return;
                }
                _this._answeringStatusChange({
                    type: EventSet.eChatAnswerPollingType.sent,
                    data: data,
                });
                _this._startPolling();
            }else {
                _this._answeringStatusChange({
                    type: EventSet.eChatAnswerPollingType.sentFailed,
                    message: data.message,
                    overHook: (commitedMessage)=>{_this._answeringOverHook(commitedMessage, _this)}
                });
            }
        }).catch((reason)=>{
            _this._answeringStatusChange({
                type: EventSet.eChatAnswerPollingType.sentFailed,
                message: AppConfigs.chatConstants.unDefinedErrText(reason),
                overHook: (commitedMessage)=>{_this._answeringOverHook(commitedMessage, _this)}
            });
        });
    },

    _storeMessageObj(storeMessageObj){
        let toSaveMessageObj = {
            timestamp: storeMessageObj.timestamp,
            mid: storeMessageObj.mid,
            chattype: storeMessageObj.chattype,
            datetime: storeMessageObj.datetime,
            message: storeMessageObj.message,
        }
        ReposSession.saveMessageObj(toSaveMessageObj)
    },

    _buildDisplayMessageObj(mainMessageObj){
        let type = mainMessageObj.chattype;
        if(type == "send"){
            return {
                ...mainMessageObj,
                nickname: ReposAuth.userinfo.nickName,
                avatar: ReposAuth.userinfo.avatar
            }
        }else if(type == "receive"){
            return {
                ...mainMessageObj,
                nickname: ReposAuth.aiBotInfo.nickName,
                avatar: ReposAuth.aiBotInfo.avatar
            }
        }
    },

    /**
     * timestamp: // 23455454545l， 主键<br/>
     *     mid: // messageId， 唯一键<br/>
     *     chattype: // send | receive | other(tip)，索引<br/>
     *     datetime: // "2023-01-01 12:23:45"， 索引<br/>
     *     nickname: //昵称<br/>
     *     avatar: // "https://img.yzcdn.cn/vant/cat.jpeg"<br/>
     *     message: // "欢迎使用AI智能对话，"<br/>
     * @param text
     * @returns {{}}
     */
    _buildMainMessageObj(text, type){
        let date = new Date();
        let messageObj = {
            timestamp: CommonUtils.timestamp(date),
            mid: CommonUtils.randomUUID(),
            chattype: type,
            datetime: CommonUtils.formatTime(date),
            message: text,
        };
        return messageObj
    },
}

export default ChatManager;