<template>
    <div class="MineActivities">
      <div>请等待开放更多内容，谢谢~（也可扫描下方二维码，进入小程序参与活动）</div>
      <div style="margin-top: 20px">
        <el-image style="height: 100px;" :src="resUri('/resources/images/qrcode-minigram.png')" fit="cover"/>
      </div>

    </div>
</template>

<script src="./_MineActivities.js" lang="js"></script>
<style src="./_MineActivities.less" lang="less" scoped></style>
