// @vue/component

import {
  ElAvatar, ElIcon, ElTooltip
} from "element-plus"
import MarkdownIt from 'markdown-it'

import hljs from 'highlight.js';
import EventBus,{EventSet} from "@/js/common/eventbus";
import AppConfigs from "@/js/common/appconfig";
import ReposAuth from "@/js/repos/repos_auth";

hljs.configure({
  languages: ['javascript', 'html', 'css', 'python', 'java', 'ruby', 'shell', 'vue'],
});

// 样式文件可以根据自己的喜好进行修改

export default {

  name: 'ChatSessionMessage',

  components: {ElAvatar,ElIcon, ElTooltip, MarkdownIt},

  mixins: [],

  props: {
    messageObj: Object,
  },

  data () {
    return {
      markdownText:"# hello",
    }
  },

  computed: {
    isVip(){
      return ReposAuth.userinfo.vipLevel>0
    }
  },

  watch: {},

  created () {
    this.registerCopyMethod();
  },

  methods: {
    registerCopyMethod(){
      window.copyCode = function(codeStr){
        const decodedStr = atob(codeStr);
        const originalStr = decodeURIComponent(Array.prototype.map
            .call(decodedStr, (c) => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
            .join('')
        );

        if(navigator.clipboard){
          navigator.clipboard.writeText(originalStr).then(function() {
            console.log('复制成功！\n' + originalStr);
            EventBus.emit(EventSet.eEleMessageTip, {
              message: AppConfigs.chatConstants.copyCodeTextSuccess(),
              type: 'success',
            });
          }, function() {
            console.log('复制失败！\n' + originalStr);
            EventBus.emit(EventSet.eEleMessageTip, {
              message: AppConfigs.chatConstants.copyCodeTextFailed(),
              type: 'error',
            });
          });
        }else{
          console.log("剪贴板不可用！")
          EventBus.emit(EventSet.eEleMessageTip, {
            message: AppConfigs.chatConstants.copyUnavailable(),
            type: 'error',
          });
        }
      }
    },

    onClickResend(){
      EventBus.emit(EventSet.eChatMessageAction, {
        type: EventSet.eChatMessageActionType.resend,
        messageObj: this.messageObj
      })
    },
    onClickCopy(){
      EventBus.emit(EventSet.eChatMessageAction, {
        type: EventSet.eChatMessageActionType.copy,
        messageObj: this.messageObj
      })
      if(navigator.clipboard){
        navigator.clipboard.writeText(this.messageObj.message).then(function() {
          EventBus.emit(EventSet.eEleMessageTip, {
            message: AppConfigs.chatConstants.copyTextSuccess(),
            type: 'success',
          });
        }, function() {
          EventBus.emit(EventSet.eEleMessageTip, {
            message: AppConfigs.chatConstants.copyTextFailed(),
            type: 'error',
          });
        });
      }else{
        EventBus.emit(EventSet.eEleMessageTip, {
          message: AppConfigs.chatConstants.copyUnavailable(),
          type: 'error',
        });
      }
    },
    onClickDelete(){
      EventBus.emit(EventSet.eChatMessageAction, {
        type: EventSet.eChatMessageActionType.delete,
        messageObj: this.messageObj
      })
    },
    markdownToHtml(markdown) {
      const md = new MarkdownIt({
        highlight: (str, lang) => {
          const encodedStr = encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, (_, p1) => {
            return String.fromCharCode(parseInt(p1, 16));
          });
          const base64Str = btoa(encodedStr);

          if (lang && hljs.getLanguage(lang)) {
            try {
              return '<pre class="hljs extend-hljs" style="overflow: auto;">' +
                      '<div class="extend-bar">' +
                       '<span class="extend-button" onclick="copyCode(\''+base64Str+'\')">' +
                        '&#x1F4CB; 拷贝代码(' + lang + ')' +
                       '</span>' +
                      '</div>' +
                      '<code>' + 
                        hljs.highlight(str, {
                          language: lang,
                          ignoreIllegals: true
                        }).value +
                      '</code>' +
                     '</pre>';
            } catch (error) {
              console.warn("error highlightjs")
            }
          }
          return '<pre class="hljs extend-hljs" style="overflow: auto;">' +
                  '<div class="extend-bar">' +
                    '<span class="extend-button" onclick="copyCode(\''+base64Str+'\')">' +
                      '&#x1F4CB; 拷贝代码(' + lang + ')' +
                    '</span>' +
                  '</div>' +
                  '<code>' + md.utils.escapeHtml(str) + '</code>' +
                '</pre>';
        }
      })
      return md.render(markdown)
    }
  }
}
