import AppConfigs from "@/js/common/appconfig";
import ReposAuth from "@/js/repos/repos_auth";
import CommonUtils from "@/js/common/utils";

const requests = {
    _extParams(){
        let ver = AppConfigs.version;
        return "?v="+ver;
    },
    async post(uri, params, headers){
        // 发起 POST 请求并处理响应
        let postHeaders = {
            'Content-Type': 'application/json',
            'loginToken': ReposAuth.loginToken(),
        }
        let url = AppConfigs.serverHost + uri + this._extParams();

        if(headers){
            postHeaders = {
                ...postHeaders,
                ...headers,
            };
        }
        if(!params){
            params = {};
        }

        let reqId = CommonUtils.randomId();
        console.log(`request[time=${CommonUtils.now()}][method=POST][reqId=${reqId}]: ------> ${url}, params=${JSON.stringify(params)}`)

        return await fetch(url, {
            method: 'POST',
            headers: postHeaders,
            body: JSON.stringify(params)
        }).then((res)=>{
            return res.json();
        }).then((data)=>{
            console.log(`response[time=${CommonUtils.now()}][method=POST][reqId=${reqId}] success: <------ ${url}, ret=${JSON.stringify(data)}`);
            return Promise.resolve(data);
        }).catch((reason)=>{
            console.error(`response[time=${CommonUtils.now()}][method=POST][reqId=${reqId}] failed: <------ ${url}, ret=${reason}`);
            return Promise.reject(reason);
        });
    },
    async get(uri, params, headers){
        // 发起 POST 请求并处理响应
        let getHeaders = {
            'Content-Type': 'application/json',
            'loginToken': ReposAuth.loginToken(),
        }
        if(headers){
            getHeaders = {
                ...getHeaders,
                ...headers,
            };
        }
        let url = AppConfigs.serverHost + uri + this._extParams();
        if(params){
            const paramsStr = Object.entries(params)
                .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
                .join("&")
            url = url + "&" + paramsStr;
        }
        let reqId = CommonUtils.randomId();

        console.log(`request[time=${CommonUtils.now()}][method=GET][reqId=${reqId}]: ------> ${url}`)

        return await fetch(url, {
            method: 'GET',
            headers: getHeaders,
        }).then((res)=>{
            return res.json();
        }).then((data)=>{
            console.log(`response[time=${CommonUtils.now()}][method=GET][reqId=${reqId}] success: <------ ${url}, ret=${JSON.stringify(data)}`);
            return Promise.resolve(data);
        }).catch((reason)=>{
            console.error(`response[time=${CommonUtils.now()}][method=GET][reqId=${reqId}] failed: <------ ${url}, ret=${reason}`);
            return Promise.reject(reason);
        });
    },

}

export default requests;