import LsAuth from "@/js/repos/ls_auth";
import RpcChat from "@/js/remote/rpc_chat";
import AppConfigs from "@/js/common/appconfig";

const ReposAuth = {
    /**
     *  {
     * 		"avatar": "wxfile://tmp_3d4bef076582bd19a6cf20bb911eb7ac.jpg",
     * 		"nickName": "小游侠",
     * 		"profile": {
     * 			"balance": 62,
     * 			"inviterCode": "GGWmzeMzmx",
     * 			"remainActivityAd": 3,
     * 			"signInToday": false
     * 		},
     * 		"uid": 10001,
     * 		"vipExpireTime": 0,
     * 		"vipLevel": 0
     * 	},
     */
    userinfo: {
        nickName: "小游侠",
        avatar: "",
    },
    aiBotInfo: {
        nickName: "黑石AI助理",
        avatar: AppConfigs.resHost + "/resources/images/robot-avatar.png"
    },
    loginToken: () => LsAuth.get().loginToken,

    setLoginToken: loginToken => LsAuth.store({loginToken}),

    logout: () => LsAuth.clear(),

    async login(code){
        let _this = this;
        return new Promise((resolve, reject) => {
            RpcChat.login({code: code}).then((data)=>{
                console.debug(`login success, userinfo = ${JSON.stringify(data)}`);
                _this.setLoginToken(data.data.loginToken)
                resolve(data);
            }).catch((reason)=>{
                console.debug(`login failed, reason = ${reason}`);
                reject(reason)
            })
        })
    },

    async getUserInfoAsync(){
        let _this = this;
        return new Promise((resolve, reject)=>{
            RpcChat.personalInfo().then((data)=>{
                if(data.code == 200){
                    console.debug(`getUserInfo success, userinfo = ${JSON.stringify(data)}`);
                    _this.userinfo = data.data;
                    resolve(data);
                    return;
                }
                console.debug(`getUserInfo failed, result = ${JSON.stringify(data)}`);
                reject(data)
            }).catch((reason)=>{
                console.debug(`getUserInfo failed, reason = ${reason}`);
                reject(reason)
            });
        });
    },

    /**
     * 根据loginToken判断是否登录成功，会拿该值去获取用户信息，能拿到就resolve，拿不到就reject
     * @returns {Promise<unknown>}
     */
    async isLogined(){
        if(this.loginToken() == null){
            console.debug("not logined")
            return Promise.reject();
        }
        let _this = this;
        return new Promise((resolve, reject)=>{
            RpcChat.personalInfo().then((data)=>{
                if(data.code == 200){
                    console.debug(`login success, userinfo = ${JSON.stringify(data)}`);
                    _this.userinfo = data.data;
                    resolve();
                    return;
                }
                console.debug(`login failed, result = ${JSON.stringify(data)}`);
                reject()
            }).catch((reason)=>{
                console.debug(`login expired, reason = ${reason}`);
                reject()
            });
        });
    }

}




export default ReposAuth;