// @vue/component
import {ElIcon, ElTooltip, ElInput} from "element-plus";
import EventBus, {EventSet} from "@/js/common/eventbus";
import ReposSession from "@/js/repos/repos_session";

export default {
  name: 'ChatSessionCard',

  components: {ElIcon,ElTooltip,ElInput},

  mixins: [],

  props: {
    sessionObj: Object,
  },

  data () {
    return {
      active: false,
      mode: "normal",
      inputName: "",
    }
  },

  computed: {},

  watch: {},

  created () {
    EventBus.on(EventSet.eChatSessionSwitch, this.onSessionSwitch)
  },

  methods: {
    onClickEditSessionName(event){
      event.stopPropagation();
      this.mode = "edit";
      this.inputName = "";
    },
    onInputKeyDown(event){
      this.onClickConfirmEdit(event)
    },
    onClickConfirmEdit(event){
      event.stopPropagation();
      this.mode = "normal";
      if(!this.inputName || this.inputName.trim().length == 0){
        return;
      }
      this.sessionObj.name = this.inputName;
      ReposSession.modifySession(this.sessionObj).then(()=>{
        console.debug("modify name success")
      })
    },
    onClickCard(){
      if(this.active){
        return;
      }
      console.log(`****** switch Sesion success, now began to switch session to:${this.sessionObj.sid} ******`)
      EventBus.emit(EventSet.eChatSessionSwitch, {sid: this.sessionObj.sid})
    },
    onSessionSwitch({sid}){
      if(sid == this.sessionObj.sid && !this.active){
        this.active = true;
        let _this = this;
        ReposSession.switchSession(this.sessionObj)
            .then(()=>{
              EventBus.emit(EventSet.eChatSessionSwitchReady, {sessionObj: _this.sessionObj})
            });

      }else{
        this.active = false;
      }
    },

    onClickDeleteSession(event){
      event.stopPropagation();
      EventBus.emit(EventSet.eChatSessionDelete, {sid: this.sessionObj.sid});
    },
  }
}
