<template>
    <div class="ChatInputArea AutoMediaWidth">
      <div class="ToolBar">
        <div class="ToolBarLeft">
          <el-button-group>
            <el-tooltip
                class="box-item"
                effect="dark"
                content="清除输入框内容"
                placement="top">
              <el-button @click="onDeleteInput" >
                <el-icon size="18px"><Delete /></el-icon>
              </el-button>
            </el-tooltip>
            <el-tooltip
                class="box-item"
                effect="dark"
                content="快捷键:  继续回答"
                placement="top">
              <el-button @click="onContinue" >
                <el-icon size="18px"><EditPen /></el-icon>
              </el-button>
            </el-tooltip>
          </el-button-group>

        </div>
        <div class="ToolBarRight">
          <el-button-group>
            <el-tooltip
                class="box-item"
                effect="dark"
                content="滚动到最顶"
                placement="top">
              <el-button type="info" @click="onScrollToTop" >
                <el-icon size="18px"><Top/></el-icon>
              </el-button>
            </el-tooltip>
            <el-tooltip
                class="box-item"
                effect="dark"
                content="滚动到最底部"
                placement="top">
              <el-button type="info" @click="onScrollToBottom" >
                <el-icon size="18px"><Bottom/></el-icon>
              </el-button>
            </el-tooltip>
          </el-button-group>
        </div>

      </div>
      <div style="border: #CDD0D6 1px solid; border-radius: 10px; margin-bottom: 10px; padding: 10px;">
        <div class="InputArea">
          <el-input
              v-model="inputText"
              :rows = "1"
              type="textarea"
              :autosize="{minRows: 1, maxRows: 12}"
              resize="none"
              placeholder="请输入问题(Shift+Enter换行)"
              class="custom-textarea"
              :maxlength="1000"
              :show-word-limit = "true"
              @keydown.enter = "onInputKeyDown"
          />
        </div>
        <div class="StatusArea">
          <div style="color: #999999; font-size: 14px;">
            提示：本AI工具的回复具有随机性，不代表黑石AI的观点，请自行甄别
          </div>
          <div style="display: flex; flex:1; justify-content: end;">
            <el-button color="#626aef" type="primary" style="height: 100%; font-size: 14px;" :dark="true" @click="onSendQuestion" :disabled="waiting">
              <el-icon style="margin-right: 5px"><Promotion /></el-icon> 发送
            </el-button>
          </div>
        </div>
      </div>
    </div>
</template>

<script src="./_ChatInputArea.js" lang="js"></script>
<style src="./_ChatInputArea.less" lang="less" scoped></style>
