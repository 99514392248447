<template>
    <div class="MineAccountInfo">
      <div :class="isVip?'PersonalInfoCardVIP':'PersonalInfoCard'">
        <div class="card-avatar">
          <el-avatar :size="80" :class="isVip?'avatar-style-vip':'avatar-style'">
            <el-icon :size="50"><User/></el-icon>
          </el-avatar>
        </div>
        <div class="card-content">
          <div class="card-line-head">
            {{ userInfo.nickName }}
            <span v-if="isVip" class="card-line-head-span">(VIP将于{{ vipExpireTime }}过期)</span>
          </div>
          <div class="card-line-tail"><span>UID:</span><span>{{ userInfo.uid }}</span></div>
          <div v-if="isVip" class="card-user-type-vip">
            纯享版
          </div>
          <div v-else class="card-user-type">普通版</div>
        </div>
      </div>
      <div class="MineAccountInfoTabs">
        <el-radio-group v-model="switchMenu" size="large" fill="rgb(111, 122, 236)" >
          <el-radio-button label="chargePage">会员升级</el-radio-button>
          <el-radio-button label="stAccount" >石币账户</el-radio-button>
        </el-radio-group>
      </div>
      <div class="MineAccountInfoTabsContent">
        <ChargePage v-if="switchMenu == 'chargePage'"/>
        <StDetail v-else-if="switchMenu == 'stAccount'"/>
      </div>
    </div>
</template>

<script src="./_MineAccountInfo.js" lang="js"></script>
<style src="./_MineAccountInfo.less" lang="less" scoped></style>
