// @vue/component

import {
  ElImage, ElIcon, ElButton, ElContainer, ElAside, ElHeader, ElMain, ElFooter,ElMessage,
  ElDivider
} from "element-plus"

import ChatInputArea from "./ChatInputArea/ChatInputArea"
import ChatSessionMessageList from "./ChatSessionMessageList/ChatSessionMessageList"
import ChatSessionList from "./ChatSessionList/ChatSessionList"
import ChatHelper from "./ChatHelper/ChatHelper"
import ChatFloatTools from "./ChatFloatTools/ChatFloatTools"

import ChatManager from "@/js/service/chatmanager";
import EventBus, {EventSet} from "@/js/common/eventbus";

export default {
  name: 'ChatPage',

  components: {
    ElImage, ElIcon, ElButton, ElContainer, ElAside, ElHeader, ElMain, ElFooter,ElMessage,
    ElDivider,
    ChatInputArea,ChatSessionMessageList,ChatSessionList,ChatHelper,ChatFloatTools
  },

  mixins: [],

  props: {},

  data () {
    return {}
  },

  computed: {},

  watch: {},

  beforeCreate() {
    EventBus.buildEventBus();
  },

  created () {
    EventBus.on(EventSet.eChatContentScrollToBottom, this.scrollToBottom);
    EventBus.on(EventSet.eChatContentScrollToTop, this.scrollToTop);
    EventBus.on(EventSet.eEleMessageTip, this.messageTip);

    ChatManager.init();

  },

  mounted() {

  },

  methods: {
    scrollToBottom(){
      setTimeout(()=>{
        let content = this.$refs.scrollChatContent;
        if(!content){
          return;
        }
        content.scrollTop = content.scrollHeight - content.clientHeight;
        //console.log("scroll to bottom", content.scrollTop, content.scrollHeight, content.clientHeight)
      }, 200)
    },
    scrollToTop(){
      let content = this.$refs.scrollChatContent;
      content.scrollTop = 0;
    },

    messageTip(options){
      let defaultOptions = {
        duration: 1500,
      }
      ElMessage({
        ...defaultOptions,
        ...options
      })
    },
  }
}
