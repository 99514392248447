import IDB_Enums from "@/js/repos/idb_enums";

//const gDBName = "ChatMessageStore";

/**
 * 版本1的数据表定义：<br/>
 * <pre>
 * {
 *     timestamp: // 23455454545l， 主键
 *     mid: // messageId， 唯一键
 *     chattype: // send | receive | other(tip)，索引
 *     datetime: // "2023-01-01 12:23:45"， 索引
 *     nickname: //昵称
 *     avatar: // "https://img.yzcdn.cn/vant/cat.jpeg"
 *     message: // "欢迎使用AI智能对话，"
 * }
 * </pre>
 */
export class IDB_Chat{
    constructor(tbName, dbVersion) {
        this.db = null;
        this.tbName = tbName;
        this.dbVersion = dbVersion;
    }
    _upgradeneeded(event){
        const db = event.target.result;
        let tbName = this.tbName;
        if(db.objectStoreNames.contains(tbName)){
            console.warn(`table ${tbName} already exist!`);
            return;
        }
        const ChatMessageStore = db.createObjectStore(tbName, { keyPath: 'timestamp' });

        ChatMessageStore.createIndex('mid', 'mid', { unique: true });
        ChatMessageStore.createIndex('chattype', 'chattype', { unique: false });
        ChatMessageStore.createIndex('datetime', 'datetime', { unique: false });

        console.log(`table ${tbName} create success!`);
    }
    async initOrOpen(){
        let _this = this;
        return new Promise((resolve, reject)=>{
            if(_this.db){
                console.log("IDB has been created!", _this.db)
                reject("IDB has been created!");
                return;
            }
            //使用tbName作为数据库名
            const openRequest = indexedDB.open(_this.tbName, _this.dbVersion);
            openRequest.onupgradeneeded = function(event) {
                // 指定需要存储的数据结构，例如对象仓库
                _this._upgradeneeded(event);
            };
            openRequest.onsuccess = function(event) {
                _this.db = event.target.result;
                console.log(`table ${_this.tbName} open success!`);
                resolve();
            };
        })
    }
    closeDB(){
        if(!this.db){
            return;
        }
        if(this.db){
            this.db.close();
            this.db = null;
            console.log(`table ${this.tbName} close success! `);
        }
    }

    destroy(){
        /*if(this.db){
            this.db.deleteObjectStore(this.tbName);
            this.db.close();
            this.db = null;
            console.log(`table ${this.tbName} delete success! `);
        }*/
        const request = indexedDB.deleteDatabase(this.tbName);

        let _this =this;
        request.onsuccess = function() {
            console.log('Database deleted successfully', _this.tbName);
        };

        request.onerror = function() {
            console.log('Failed to delete database', _this.tbName);
        }
    }
    count(cb_receiver){
        let db = this.db;
        const transaction = db.transaction([this.tbName], 'readonly');
        const objectStore = transaction.objectStore(this.tbName);

        const req = objectStore.count();
        req.onsuccess = function(event) {
            const count = event.target.result;
            cb_receiver(IDB_Enums.resultCode.success, count);
        };
    }

    add(messageObj){
        let db = this.db;

        const transaction = db.transaction([this.tbName], 'readwrite');
        const objectStore = transaction.objectStore(this.tbName);
        const request = objectStore.add(messageObj);

        request.onsuccess = function(event) {
            console.debug('数据已保存到对象仓库中', event);
            transaction.commit();
        };
        request.onerror = function(error) {
            console.error('数据存储到对象仓库中失败', error);
            transaction.abort();
        }
    }

    addOrReplace(messageObj){
        let db = this.db;

        const transaction = db.transaction([this.tbName], 'readwrite');
        const objectStore = transaction.objectStore(this.tbName);
        const request = objectStore.put(messageObj);

        request.onsuccess = function(event) {
            console.debug('数据已保存到对象仓库中', event);
            transaction.commit();
        };
        request.onerror = function(error) {
            console.error('数据存储到对象仓库中失败', error);
            transaction.abort();
        }
    }

    /**
     * 从倒数offset开始，获取最近的limit条记录
     * @param offset 倒数第几个，从0开始。
     * @param limit 获取的记录个数
     * @param db_receiver 最后以(IDB_Enums.resultCode, [messageObj]|errEvent)结果返回
     */
    getLatest(offset, limit, db_receiver){
        let db = this.db;
        const transaction = db.transaction(this.tbName);
        const store = transaction.objectStore(this.tbName);

        const req = store.openCursor(null, "prev");

        let results = [];
        let cnt = 0;

        req.onsuccess = function(event) {
            const cursor = event.target.result;
            if(!cursor){
                console.debug(`返回 ${results.length} 条存储记录: `, results);
                db_receiver(IDB_Enums.resultCode.success, results);
                return;
            }
            if(cnt>=offset){
                results.push(cursor.value);
                if(results.length>=limit){
                    console.debug(`返回 ${results.length} 条存储记录: `, results);
                    db_receiver(IDB_Enums.resultCode.success, results);
                    return;
                }
            }
            cursor.continue();
            cnt += 1;
        };

        req.onerror = function(event) {
            console.error(`检索存储记录时出错: `, event);
            db_receiver(IDB_Enums.resultCode.error, event);
        };

    }

    get(mid, cb_receiver){
        let db = this.db;

        const transaction = db.transaction([this.tbName], 'readonly');
        const store = transaction.objectStore(this.tbName);
        const index = store.index('mid');

        const req = index.get(mid);

        req.onsuccess = function(event) {
            const result = event.target.result;

            if (result) {
                console.debug(`通过mid[${mid}]检索到存储记录[,]: `, result);
                cb_receiver(IDB_Enums.resultCode.success, result);
            } else {
                console.debug(`没有找到mid[${mid}]的存储记录`);
                cb_receiver(IDB_Enums.resultCode.notfound, null);
            }
        };

        req.onerror = function(event) {
            console.error(`检索mid[${mid}]存储记录时出错: `, event);
            cb_receiver(IDB_Enums.resultCode.error, event);
        };
    }

    delete(mid){
        let db = this.db;

        const transaction = db.transaction([this.tbName], 'readwrite');
        const objectStore = transaction.objectStore(this.tbName);

        const index = objectStore.index("mid");
        const request = index.get(mid);

        request.onsuccess = function(event) {
            const message = event.target.result;
            objectStore.delete(message.timestamp);
            console.debug(`成功删除 mid 为 ${mid} 的消息记录`);
            transaction.commit();
        };

        request.onerror = function(event) {
            console.error(`删除 mid 为 ${mid} 的消息记录失败, ${event}`);
            transaction.abort();
        };

    }
}
