<template>
    <div class="ChatSessionList">
      <div class="ChatNewSession">
        <el-button class="ChatNewSessionBtn" color="#626aef" type="primary" :dark="true" @click="onClickNewSession">
          <el-icon style="padding-right: 5px;"><Plus /></el-icon>创建新会话
        </el-button>
        <el-divider style="margin: 15px 0px 0px" />
      </div>
      <div class="ChatSessionCards" ref="scrollChatSessionList">
        <ChatSessionCard
            v-for="session in sessionList"
            :key="session.timestamp"
            :sessionObj="session">
        </ChatSessionCard>
      </div>
      <div class="ChatSessionFetchMore">
        <el-text v-if="hasMore" class="mx-1 ChatSessionFetchMoreBtn" type="primary" @click="onFetchMoreSession">获取更多历史会话</el-text>
        <el-text v-else class="mx-1" type="info">已经没有更多历史会话了~</el-text>
        <el-tooltip
            class="box-item"
            effect="dark"
            content="返回顶部"
            placement="top-start"
        >
          <el-icon class="ChatSessionToolIcon" @click="onClickBackToTop"><Top /></el-icon>
        </el-tooltip>
      </div>
    </div>
</template>

<script src="./_ChatSessionList.js" lang="js"></script>
<style src="./_ChatSessionList.less" lang="less" scoped></style>
