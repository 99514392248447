import CommonUtils from "@/js/common/utils";
import IDB_Enums from "@/js/repos/idb_enums";

const gDBName = "ChatMetaStore";
const gTbName = "ChatMetaTable";

function _upgradeneededV1(event, tbName){
    const db = event.target.result;
    const ChatMetaStore = db.createObjectStore(tbName, { keyPath: 'timestamp' });

    ChatMetaStore.createIndex('uid', 'uid');

    console.log(`table ${tbName} create success!`);
}

const gDBUpgradeNeededMap = {
    1 : _upgradeneededV1
}

class IDB_Meta{
    constructor({tbName}) {
        this.db = null;
        this.tbName = tbName;
        this.dbVersion = 1;
    }
    _upgradeneeded(event){
        let needed = gDBUpgradeNeededMap[this.dbVersion];
        needed(event, this.tbName);
    }
    info(){
        return {
            dbName: gDBName,
            tbName: this.tbName,
            dbVersion: this.dbVersion,
            inited: !!this.db
        }
    }

    /**
     * <pre>
     * resolve(IDB_Enums.resultCode.success);
     * or
     * reject(IDB_Enums.resultCode.error);
     * </pre>
     * @returns {Promise<unknown>}
     */
    async init(){
        if(this.db){
            console.log("IDB_ChatMeta has been created!", this.db)
            return Promise.reject(IDB_Enums.resultCode.error);
        }
        let _this = this;

        return new Promise((resolve, reject) => {
            const openRequest = indexedDB.open(gDBName, this.dbVersion);
            openRequest.onupgradeneeded = function(event) {
                // 指定需要存储的数据结构，例如对象仓库
                _this._upgradeneeded(event);
            };
            openRequest.onsuccess = function(event) {
                _this.db = event.target.result;
                if(_this.db){
                    console.log(`table ${_this.tbName} open success!`);
                }
                resolve(IDB_Enums.resultCode.success);
            };
            openRequest.onerror = function(err){
                console.error(`table ${_this.tbName}  open error`, err)
                reject(IDB_Enums.resultCode.error);
            }
        })

    }
    destroy(){
        if(this.db){
            this.db.deleteObjectStore(this.tbName);
            this.db.close();
            this.db = null;
            console.log(`table ${this.tbName} delete success! `);
        }
    }

    _defaultMetaObj(uid){
        return {
            timestamp: CommonUtils.currentTimeMS(),
            uid: uid,
            dbName: "#Sess#"+uid,
            dbVersion: CommonUtils.currentTimeMS()
        }
    }

    /**
     * <pre>
     * resolve(savedObj);
     * or
     * reject(IDB_Enums.resultCode.error);
     * </pre>
     * @param metaObj
     * @returns {Promise<unknown>}
     */
    addAsync(metaObj){
        let db = this.db;
        let _this = this;
        return new Promise((resolve, reject)=>{
            const transaction = db.transaction([_this.tbName], 'readwrite');
            const objectStore = transaction.objectStore(_this.tbName);
            const request = objectStore.add(metaObj);

            request.onsuccess = function(event) {
                console.debug('数据已保存到对象仓库中', event);
                transaction.commit();
                resolve(metaObj);
            };
            request.onerror = function(error) {
                console.error('数据存储到对象仓库中失败', error);
                transaction.abort();
                reject(IDB_Enums.resultCode.error);
            }
        });

    }

    addOrReplace(metaObj){
        let db = this.db;

        const transaction = db.transaction([this.tbName], 'readwrite');
        const objectStore = transaction.objectStore(this.tbName);
        const request = objectStore.put(metaObj);

        request.onsuccess = function(event) {
            console.debug('数据已保存到对象仓库中', event);
            transaction.commit();
        };
        request.onerror = function(error) {
            console.error('数据存储到对象仓库中失败', error);
            transaction.abort();
        }
    }

    /**
     * 获取uid的MetaObj，如果没有就创建一个，然后返回
     * <pre>
     * resolve(IDB_Enums.resultCode.success);
     * or
     * reject(IDB_Enums.resultCode.error);
     * </pre>
     * @param uid
     * @param key
     * @returns {Promise<unknown>}
     */
    getAsync(uid){
        let db = this.db;
        let _this = this;
        return new Promise((resolve, reject)=>{
            const transaction = db.transaction([_this.tbName], 'readonly');
            const store = transaction.objectStore(_this.tbName);
            const index = store.index('uid');

            const req = index.get(uid);

            req.onsuccess = function(event) {
                const result = event.target.result;
                if(result){
                    console.debug(`通过 uid[${uid}]检索到存储记录: `, result);
                    resolve(result);
                }else{
                    let defaultMetaObj = _this._defaultMetaObj(uid);
                    _this.addAsync(defaultMetaObj).then((savedObj)=>{
                        resolve(savedObj);
                    }).catch(err => reject(err));
                    console.debug(`不存在，返回新建的 uid[${uid}]存储记录: `, defaultMetaObj);
                }
            };
            req.onerror = function(event) {
                console.error(`检索 uid[${uid}]存储记录时出错: `, event);
                reject(IDB_Enums.resultCode.error);
            };
        })

    }
}
/**
 * 版本1的数据表定义：<br/>
 *     <pre>
 *         metaObj: {
 *             timestamp: "327933309349", //时间戳，主键
 *             uid: "#2343", //会话id，唯一键
 *             key: "dbVersion", // 会话名，可修改
 *             value: "ML#3243" // 关联的表名称
 *         }
 *     </pre>
 *
 */
const idbMeta = new IDB_Meta({tbName: gTbName})

export default idbMeta;
