<template>
    <div class="ChatFloatTools">
      <el-tooltip
          class="box-item"
          effect="dark"
          content="购买VIP纯享版"
          placement="right-start"
      >
        <el-icon :size="20" class="ChatFloatToolsVipIcon" @click="onClickShoppingCart"><ShoppingCart /></el-icon>
      </el-tooltip>
      <el-tooltip
          class="box-item"
          effect="dark"
          content="展示/隐藏使用示例"
          placement="right-start"
      >
        <el-icon :size="20" class="ChatFloatToolsIcon" @click="onClickMagicStick"><MagicStick /></el-icon>
      </el-tooltip>
      <el-popover
          ref="popover"
          placement="right"
          title="分享黑石AI体验馆"
          :width="300"
          trigger="hover"
      >
        <template #reference>
          <el-icon :size="20" class="ChatFloatToolsIcon" @click="onClickShare"><Share /></el-icon>
        </template>
        <div>
          <div>点击下载海报，分享给好友</div>
          <div style="margin-top:10px">
            <el-image :src="resUri('/resources/images/share-ad.jpg')" fit="cover"/>
          </div>
          <div style="margin:10px auto 0px; display: flex; justify-content: center;">
            <el-button color="#626aef" type="primary" :dark="true" @click="onDownloadAD">
              <el-icon style="padding-right: 5px;"><Download /></el-icon>下载海报
            </el-button>
          </div>
        </div>
      </el-popover>
      <el-popover
          ref="popover"
          placement="right"
          title="联系客服"
          :width="300"
          trigger="hover"
      >
        <template #reference>
          <el-icon :size="20" class="ChatFloatToolsIcon" @click="onClickService"><Service /></el-icon>
        </template>
        <div>
          <div>微信扫描下方二维码，添加客服</div>
          <div style="margin-top:10px">
            <el-image :src="resUri('/resources/images/contact.png')" fit="cover"/>
          </div>
        </div>
      </el-popover>
    </div>
</template>

<script src="./_ChatFloatTools.js" lang="js"></script>
<style src="./_ChatFloatTools.less" lang="less" scoped></style>
