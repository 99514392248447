import { createRouter, createWebHistory } from 'vue-router';

import HomePage from "@/components/HomePage/HomePage";
import ChatPage from "@/components/ChatPage/ChatPage";
import JoinUsPage from "@/components/JoinUsPage/JoinUsPage";
import MinePage from "@/components/MinePage/MinePage";

export const routerPages = {
    Root: "/",
    HomePage: "/chat",
    ChatPage: "/chat/aichat",
    MinePage: "/chat/mine",
    JoinUsPage: "/chat/joinus",
}


const routes = [
    { path: routerPages.Root, component: HomePage },
    { path: routerPages.HomePage, component: HomePage },
    { path: routerPages.ChatPage, component: ChatPage },
    { path: routerPages.MinePage, component: MinePage },
    { path: routerPages.JoinUsPage, component: JoinUsPage}
];

const router = createRouter({
    history: createWebHistory(),
    routes
});

export default router;