// @vue/component
import {ElIcon, ElAvatar, ElRadioGroup, ElRadioButton} from "element-plus";

import ChargePage from "@/components/common/ChargePage/ChargePage"
import StDetail from "./StDetail/StDetail"
import ReposAuth from "@/js/repos/repos_auth";
import CommonUtils from "@/js/common/utils";

export default {
  name: 'MineAccountInfo',

  components: {
    ElIcon, ElAvatar, ElRadioGroup, ElRadioButton,
    ChargePage,StDetail
  },

  mixins: [],

  props: {},

  data () {
    return {
      switchMenu: "chargePage"
    }
  },

  computed: {
    isVip(){
      return ReposAuth.userinfo.vipLevel>0
    },
    userInfo(){
      return ReposAuth.userinfo
    },
    vipExpireTime(){
      return CommonUtils.formatSeconds(ReposAuth.userinfo.vipExpireTime)
    }
  },

  watch: {},

  created () {},

  methods: {}
}
