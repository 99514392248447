import RpcChat from "@/js/remote/rpc_chat";

const MineManager = {

    async activeVip({code, usage, days}){
        let params = {
            vipCode : code,
            usage : usage? usage: "vip",
            days :days
        }
        if(usage == "vip"){
            return RpcChat.activeVipCode(params);
        }
        return RpcChat.activeVipBySt(params);
    },
}

export default MineManager;