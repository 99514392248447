<template>
    <div class="HomePage">
      <div style="position: absolute; left: 0px; top:58px">
        <el-image style="height: 550px; " :src="resUri('/resources/images/ai-bg.jpg')" fit="cover"/>
        <div class="BackgroundImgCover"></div>
      </div>
      <div class="Title" >
        <h1 class="Title-sub1">
          欢迎每一个拥有好奇心的同学，在这里学习使用最智能的AI工具
        </h1>
        <h3 class="Title-sub2">
          请使用PC端浏览器打开下列网址：
        </h3>
        <div class="Title-sub2">
          <el-link class="Title-sub2-link" href="https://ibstech.cn" target="_blank">https://ibstech.cn</el-link>
          <el-icon size="22" style="cursor: pointer" @click="copyLink"><DocumentCopy /></el-icon>
        </div>
      </div>
      <div class="Contact-Area">
        <div class="Contact-Card">
          <div class="Contact-Card-Image">
            <el-image style="height: 100px;" :src="require('@/assets/logo.png')" fit="cover"/>
          </div>
          <div class="Contact-Card-Content">
            <div>Web版</div>
            <div class="Contact-Card-Content-Sub2">请点击
              <span class="WxLoginLink" @click="redirectToWxLogin()">"微信登录"</span>
            </div>
          </div>
        </div>
        <div class="Contact-Card">
          <div class="Contact-Card-Image">
            <el-image style="height: 100px;" :src="resUri('/resources/images/qrcode-minigram.png')" fit="cover"/>
          </div>
          <div class="Contact-Card-Content">
            <div>小程序</div>
            <div class="Contact-Card-Content-Sub2">AI体验馆小程序版</div>
          </div>
        </div>
        <div class="Contact-Card">
          <div class="Contact-Card-Image">
            <el-image style="height: 100px;" :src="resUri('/resources/images/qrcode-dingyue.jpg')" fit="cover"/>
          </div>
          <div class="Contact-Card-Content">
            <div>订阅号</div>
            <div class="Contact-Card-Content-Sub2">新鲜资讯速递</div>
          </div>
        </div>
      </div>

      <div class="Product-Area">
        <div class="Product-Switch-Area">
          <div class="Product-Switch-Area-Title">黑石AI产品介绍</div>
          <el-radio-group v-model="selectedLabel" size="large" fill="#626aef">
            <el-radio-button label="Web版" />
            <el-radio-button label="小程序版" />
          </el-radio-group>
        </div>
        <div v-if="selectedLabel=='小程序版'" class="Product-Content-Area">
          <div class="Product-Content-Left">
            <el-image class="Product-Content-Left-Image" :src="resUri('/resources/images/minigram-demo1.jpg')" fit="cover"/>
          </div>
          <div class="Product-Content-Right">
            <div class="Desc">
              <div class="Desc-Title">
                小程序版，随处可用的AI助理
              </div>
              <div class="Desc-Content">
                有微信就能用，像朋友一样对话，本地保存，安全又不丢失
              </div>
            </div>
            <div class="Desc">
              <div class="Desc-Title">
                GPT3.5加持，智能满分
              </div>
              <div class="Desc-Content">
                对接当下最智能的GPT3.5，助你生活工作加成
              </div>
            </div>
            <div class="Desc">
              <div class="Desc-Title">
                丰富的AI工具箱等待开启
              </div>
              <div class="Desc-Content">
                移动版专享配音能力，让AI开口说话，更多工具，即将加入
              </div>
            </div>
            <div class="Desc">
              <div class="Desc-Title">
                快捷入口，微信扫描下方二维码
              </div>
              <div class="Desc-Content">
                <el-image style="height: 100px;" :src="resUri('/resources/images/qrcode-minigram.png')"  fit="cover"/>
              </div>
            </div>
          </div>
        </div>
        <div v-if="selectedLabel=='Web版'" class="Product-Content-Area">
          <div class="Product-Content-Left">
            <el-image class="Product-Content-Left-Image" :src="resUri('/resources/images/webai-demo1.jpg')"  fit="cover"/>
          </div>
          <div class="Product-Content-Right">
            <div class="Desc">
              <div class="Desc-Title">
                Web版，接近原版ChatGPT的大屏体验
              </div>
              <div class="Desc-Content">
                自由创建多个会话，本地保存，安全又不丢失。支持Markdown渲染，代码高亮
              </div>
            </div>
            <div class="Desc">
              <div class="Desc-Title">
                GPT3.5加持，智能满分
              </div>
              <div class="Desc-Content">
                对接当下最智能的GPT3.5，助你生活工作效率加成
              </div>
            </div>
            <div class="Desc">
              <div class="Desc-Title">
                丰富的AI工具箱等待开启
              </div>
              <div class="Desc-Content">
                快速迭代的AI工具即将加入
              </div>
            </div>
            <div class="Desc">
              <div class="Desc-Title">
                快捷入口，微信授权登录
              </div>
              <div class="Desc-Content">
                点击网站上方“登录|注册”，马上进入
              </div>
            </div>
          </div>
        </div>

      </div>

      <el-divider style="margin-bottom: 0px !important;"></el-divider>
      <div class="Footer-Area">
        <el-text class="Footer-Item" @click="openExternalLink(companyUrl)">
          主办单位：上海无线黑石信息技术有限公司
        </el-text>
        <el-text class="Footer-Item" @click="openExternalLink(ICPUrl)">
          沪ICP备2023005318号
        </el-text>
        <el-text class="Footer-Item">
          联系方式：邮件(heshi@ibstech.cn)
        </el-text>
      </div>
    </div>
</template>

<script src="./_HomePage.js" lang="js"></script>
<style src="./_HomePage.less" lang="less" scoped></style>
