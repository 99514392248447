// event-bus.js（事件总线模块）
import mitt from "mitt"

/**
 * 注意：为防止总线事件变得不可控，在emit事件时，要求事件名要求遵循以下规范<br/>
 * <br/>
 * 事件名 := 命名空间.事件<br/>
 * 命名空间 := 发送事件所属的组件名称，可以标记事件来源，例如ChatSessionMessage<br/>
 * 事件 := 业务自定义的事件名称，如Delete<br/>
 * <br/>
 * 示例：EventBus.emit("ChatSessionMessage.Delete", {param1: value1, param2: value2});
 */

// 将实例导出为默认对象
class EventBus {

    buildEventBus(){
        this.__bus = mitt();
        console.log("EventBus init!")
    }

    on(type, handler){
        this.__bus.on(type, handler);
    }
    emit(type, event){
        this.__bus.emit(type, event);
    }
}
const _EventBus = new EventBus();
export const EventSet = {

    //{text: this.inputText}，提交问题
    eChatInput : "ChatInputArea.sendQuestion",

    //{prompt: prompt}，点击prompt
    eChatInputPrompt : "ChatHelper.prompt",
    eChatToolsClickPrompt : "ChatFloatTools.clickPrompt",

    /**
     * 参数，枚举值：EventSet.eChatAnswerPollingType：<br>
     * {type: start|sent|polling|over, data: data}<br>
     * 或：<br>
     * {type: sentFailed|pollingFailed, message: message}
     */
    eChatAnswerPolling : "ChatManager.answering",
    eChatAnswerPollingType : {
      start : "start",
      sent : "sent",
      polling : "polling",
      over : "over",

      sentFailed: "sentFailed",
      pollingFailed: "pollingFailed"
    },

    //{messageObj: {@See idb_chat.js}}，messageObj组装好，可以展示了
    eChatMessageShow : "ChatManager.displayMessageObjReady",

    //{newVal, oldVal}
    eChatMessageListLengthChanged : "ChatSessionMessageList.lengthChange",

    //{mid}
    eChatMessageDelete : "ChatSessionMessage.deleteMessage",

    eChatContentScrollToBottom : "AnyWhere.chatContentScrollToBottom",
    eChatContentScrollToTop : "AnyWhere.chatContentScrollToTop",

    /**
     * 示例：
     *  <pre>
        EventBus.emit(EventSet.eEleMessageTip, {
            message: "这是一段提示信息",
            type: 'warning', //success, info, warning, error
        });
     * </pre>
     */
    eEleMessageTip : "AnyWhere.ElementUIMessageTip",

    //{eChatMessageActionType, messageObj}
    eChatMessageAction: "ChatSessionMessage.messageAction",
    eChatMessageActionType:{
        resend: "resend",
        copy:"copy",
        delete:"delete"
    },

    /**
     * {sid: activeSessionSid}
     */
    eChatSessionSwitch : "ChatSessionList.sessionSwitch",
    eChatSessionSwitchReady : "ChatSessionCard.sessionSwitchReady",

    /**
     * {sid: sessionSid}
     */
    eChatSessionDelete : "ChatSessionCard.sessionDelete",

}
export default _EventBus;

