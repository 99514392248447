// @vue/component
import {ElImage} from "element-plus";
import AppConfigs from "@/js/common/appconfig";

export default {
  name: 'MineActivities',

  components: {ElImage},

  mixins: [],

  props: {},

  data () {
    return {}
  },

  computed: {},

  watch: {},

  created () {},

  methods: {
    resUri(uri){
      return AppConfigs.resHost + uri;
    },
  }
}
