// @vue/component
import EventBus, {EventSet} from "@/js/common/eventbus";

import {ElIcon, ElButton, ElButtonGroup, ElInput, ElContainer, ElAside, ElMain, ElTooltip} from "element-plus"

import AppConfigs from "@/js/common/appconfig";
import ChatManager from "@/js/service/chatmanager";

export default {
  name: 'ChatInputArea',

  components: {
    ElIcon, ElButton, ElButtonGroup, ElInput, ElContainer, ElAside, ElMain, ElTooltip
  },

  mixins: [],

  props: {},

  data () {
    return {
      inputText: '',
      waiting: false,
    }
  },

  computed: {},

  watch: {},

  created () {
    EventBus.on(EventSet.eChatAnswerPolling, this.onChatAnswerPolling);
    EventBus.on(EventSet.eChatMessageAction, this.onChatMessageActions);
    EventBus.on(EventSet.eChatSessionSwitch, this.onSessionSwitch);
    EventBus.on(EventSet.eChatInputPrompt, this.onChatGetPropmt);
  },

  methods: {
    onSessionSwitch(){
      this.inputText = ''
      this.waiting = false;
    },
    onChatMessageActions({type,messageObj}){
      if(type == EventSet.eChatMessageActionType.resend){
        this.inputText = messageObj.message;
        this.onSendQuestion();
      }
    },
    onChatGetPropmt({prompt}){
      if(prompt && prompt.length>0){
        this.inputText = prompt;
      }
    },

    onInputKeyDown(event){
      if(event.shiftKey){
        return;
      }
      event.preventDefault();
      this.onSendQuestion();
    },

    // eslint-disable-next-line no-unused-vars
    onSendQuestion(_){
      if(this.waiting){
        EventBus.emit(EventSet.eEleMessageTip, {
          message: AppConfigs.chatConstants.answeringTipText(),
          type: 'warning',
        });
        return;
      }
      if(this.inputText.trim() == ''){
        EventBus.emit(EventSet.eEleMessageTip, {
          message: AppConfigs.chatConstants.inputEmptyTipText(),
          type: 'warning',
        });
        return;
      }
      EventBus.emit(EventSet.eChatInput, {text: this.inputText});
      ChatManager.handleInput({text: this.inputText, reset: false});

      this.inputText = ""
      this.waiting = true
    },

    onChatAnswerPolling(payload) {
      let type = payload.type;
      let enums = EventSet.eChatAnswerPollingType;

      if (type == enums.over || type == enums.pollingFailed || type == enums.sentFailed) {
        console.log("over event, reset input");
        this.waiting = false;
      }
    },

    // eslint-disable-next-line no-unused-vars
    onScrollToTop(_){
      EventBus.emit(EventSet.eChatContentScrollToTop)
    },
    // eslint-disable-next-line no-unused-vars
    onScrollToBottom(_){
      EventBus.emit(EventSet.eChatContentScrollToBottom)
    },
    onDeleteInput(){
      this.inputText = '';
    },
    onContinue(){
      this.inputText = '继续';
      this.onSendQuestion();
    }
  }
}
