<template>
    <div class="MinePage">
      <div class="MineMenu">
        <div v-for="(item, key) in menuItems" v-bind:key="key">
          <div :class="'MineMenuItem'+((key==selectedItem)?' MineMenuItemSelected':'')" @click="onSelectMenuItem(key)">
            <el-icon :size="20">
              <component :is="item.icon"></component>
            </el-icon>
            {{item.name}}
          </div>
        </div>
      </div>
      <div class="MineContent">
        <component :is="menuItems[selectedItem].page"></component>
      </div>
    </div>
</template>

<script src="./_MinePage.js"></script>
<style src="./_MinePage.less" lang="less" scoped></style>
