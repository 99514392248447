// @vue/component

import {ElTabs, ElTabPane, ElInput, ElButton, ElLink, ElImage, ElIcon, ElLoading, ElMessageBox, ElMessage, ElInputNumber} from "element-plus";

import MineManager from "@/js/service/minemanager";
import ReposAuth from "@/js/repos/repos_auth";
import CommonUtils from "@/js/common/utils";
import AppConfigs from "@/js/common/appconfig";

export default {
  name: 'ChargePage',

  components: {ElTabs, ElTabPane, ElInput, ElButton, ElLink, ElImage, ElIcon, ElLoading, ElInputNumber},

  mixins: [],

  props: {},

  data () {
    return {
      inputCode:"",
      inputNum: 1,
      isLoading: true,
    }
  },

  computed: {
    userInfo(){
      return ReposAuth.userinfo
    },
  },

  watch: {},

  created () {

  },

  methods: {
    resUri(uri){
      return AppConfigs.resHost + uri;
    },
    doActiveVip({code, usage, days}){

      let loadingInst = ElLoading.service({
        text: "正在激活/兑换"
      });

      let __activeError = function(){
        ElMessageBox.confirm(
            usage == 'vip' ? '激活码无效或已被使用，请检查输入' : '石币余额不足',
            '错误提示',
            {
              confirmButtonText: '我知道了',
              type: 'error',
              showCancelButton: false,
            }).then(() => {
          loadingInst.close();
        })
      }
      MineManager.activeVip({
        code : code,
        usage: usage,
        days: days
      }).then((data)=>{
        console.log(data)
        if(data.code != 200){
          __activeError()
          return;
        }
        ReposAuth.getUserInfoAsync().then((data)=>{
          console.log("refresh userInfo after activeCode", data)
        });
        ElMessageBox.confirm(
            `您已成功激活为会员，本次会员持续至${CommonUtils.formatSeconds(data.data.expireTime)}`,
            '激活成功',
            {
              confirmButtonText: '我知道了',
              type: 'success',
              showCancelButton: false,
            }).then(() => {
          loadingInst.close();
        })
      }).catch(() => {
        __activeError()
      })
    },

    onClickExchange(){
      if(!this.inputNum || Number.isNaN(this.inputNum)){
        ElMessage.error({
          message: "请输入数字"
        });
        return;
      }

      this.doActiveVip({
        usage: "st",
        days: this.inputNum
      })
    },

    onClickActive(){
      if(!this.inputCode || this.inputCode.trim().length == 0){
        ElMessage.error({
          message: "输入的激活码无效，请检查输入"
        });
        return;
      }
      this.doActiveVip({
        code: this.inputCode,
        usage: "vip"
      })
    },
    openExternalLink(url){
      window.open(url);
    },
  }
}
