// @vue/component
import { ElImage, ElLink, ElRadioGroup, ElRadio, ElRadioButton,ElMessage,ElDivider,ElText, ElIcon} from "element-plus"
import AppConfigs from "@/js/common/appconfig";

export default {
  name: 'HomePage',

  components: {ElImage, ElLink, ElRadioGroup, ElRadio, ElRadioButton,ElMessage, ElDivider, ElText, ElIcon},

  mixins: [],

  props: {},

  data () {
    return {
      selectedLabel : "Web版",

      companyUrl: "https://shiming.gsxt.gov.cn/corp-query-homepage.html",
      ICPUrl:"https://beian.miit.gov.cn/#/Integrated/recordQuery",
    }
  },

  computed: {},

  watch: {},

  created () {},

  methods: {
    resUri(uri){
      return AppConfigs.resHost + uri;
    },

    openExternalLink(url){
      window.open(url);
    },

    redirectToWxLogin(){
      this.redirectToLink(AppConfigs.wxLoginUrl)
    },

    redirectToLink(url){
      window.location.href = url;
    },

    copyLink(){
      if(navigator.clipboard){
        navigator.clipboard.writeText(AppConfigs.webHost).then(function() {
          ElMessage({
            message: "拷贝成功",
            type: "success"
          });
        }, function() {
          ElMessage({
            message: "拷贝失败",
            type: "error"
          });
        });
      }else{
        ElMessage({
          message: "拷贝失败",
          type: "error"
        });
      }
    },
  }
}
