<template>
    <div class="ChatHelper" v-if="show">
      <div class="HelperTitle">
        <el-icon :size="80">
          <MagicStick />
        </el-icon>
        <div>使用示例</div>
      </div>
      <div class="Content-Wrapper">
        <div class="HelperContent">
          <div v-for="(item, key) in helpItems" v-bind:key="key" @click="onClickHelperItem(key, item)" class="HelperItem">
            <div class="HelperItem-Title">{{item.title}}</div>
            <div class="HelperItem-Content">{{item.prompt.substring(0, 20) + "..."}}</div>
          </div>
          <div class="HelperItem" @click="onClickMore">
            <div class="HelperItem-Title">
              <el-icon><MoreFilled /></el-icon>
            </div>
            <div class="HelperItem-Content">更多示例点这里</div>
          </div>
        </div>

        <div class="HelperHide" @click="onClickHide">
          <el-icon><Hide /></el-icon>
          &nbsp;&nbsp;隐藏使用示例
        </div>
      </div>
    </div>
</template>

<script src="./_ChatHelper.js" lang="js"></script>
<style src="./_ChatHelper.less" lang="less" scoped></style>
