<template>
    <div class="ChargePage">
      <div class="charge-panel">
        <div class="BlockTitle">会员兑换方式</div>
        <el-tabs :tab-position="'left'" >
          <el-tab-pane label="激活码激活">
            <div class="charge-form">
              <el-input
                  v-model="inputCode"
                  class="w-50 m-2"
                  size="large"
                  placeholder="请输入激活码"
                  maxlength="23"
                  show-word-limit
              />
              <el-button color="#626aef" type="primary" :dark="true" @click="onClickActive">
                <el-icon style="padding-right: 5px;"><Switch /></el-icon>立即激活
              </el-button>
            </div>
            <div class="charge-tips">
              激活码获取方式：
              <div>1、点击 <el-link type="primary" href="https://shop347110916.taobao.com/?spm=2013.1.1000126.2.20e168f8G8UoeS" target="_blank">黑石AI体验馆</el-link>
                到淘宝小店购买。当前激活码共5种，分别为5天、15天、30天、180天和360天。</div>
              <div>2、扫描下方二维码，加客服微信。客服将会邀请您加入我们的用户群，用户群会举办活动并放松激活码</div>
              <div style="margin-top:10px;">
                <el-image :src="resUri('/resources/images/contact-mini.png')" fit="cover"/>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="石币兑换">
            <div class="charge-form">
              <el-input-number style="flex:1" v-model="inputNum" :min="1" :max="10"/>
              <el-button color="#626aef" type="primary" :dark="true" @click="onClickExchange">
                <el-icon style="padding-right: 5px;"><Switch /></el-icon>立即兑换
              </el-button>
            </div>
            <div class="charge-tips">
              石币余额：$ST {{userInfo.profile.balance}}
            </div>
            <div class="charge-tips">
              石币获取方式：
              <div>1、由于web版活动还未开放，请扫描下方二维码，到小程序参与活动</div>
              <div style="margin-top:10px;">
                <el-image style="height: 100px;" :src="resUri('/resources/images/qrcode-minigram.png')" fit="cover"/>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="微信充值">很快就能使用啦~~</el-tab-pane>
        </el-tabs>
      </div>
      <div class="charge-panel">
        <div class="BlockTitle">会员权益说明</div>
        <div style="margin-top:10px;">
          <el-image style="max-width: 280px;" :src="resUri('/resources/images/vip-limit.png')" fit="cover"/>
        </div>
      </div>
    </div>
</template>

<script src="./_ChargePage.js" lang="js"></script>
<style src="./_ChargePage.less" lang="less" scoped></style>
