// @vue/component

import {ElTable, ElTableColumn, ElImage, ElTag} from "element-plus";
import ReposAuth from "@/js/repos/repos_auth";
import AppConfigs from "@/js/common/appconfig";


export default {
  name: 'StDetail',

  components: {ElTable, ElTableColumn, ElImage, ElTag},

  mixins: [],

  props: {},

  data () {
    return {
      tableData : [
        {
          date: '2016-05-03',
          name: 'Tom',
          address: 'No. 189, Grove St, Los Angeles',
        },
        {
          date: '2016-05-02',
          name: 'Tom',
          address: 'No. 189, Grove St, Los Angeles',
        },
        {
          date: '2016-05-04',
          name: 'Tom',
          address: 'No. 189, Grove St, Los Angeles',
        },
        {
          date: '2016-05-01',
          name: 'Tom',
          address: 'No. 189, Grove St, Los Angeles',
        },
      ]
    }
  },

  computed: {
    userInfo(){
      return ReposAuth.userinfo
    },
  },

  watch: {},

  created () {},

  methods: {
    resUri(uri){
      return AppConfigs.resHost + uri;
    },
  }
}
