import {ElMenu, ElImage, ElMenuItem, ElIcon, ElButton, ElMessage, ElMessageBox, ElTag, ElTooltip, ElDialog} from "element-plus"
import 'element-plus/theme-chalk/display.css'
import './css/hljs_extend.css'
import ReposAuth from "@/js/repos/repos_auth";
import router, {routerPages} from "@/router";
import AppConfigs from "@/js/common/appconfig";
import ReposSession from "@/js/repos/repos_session";

export default {
    name: 'App',
    components: {
        ElImage, ElMenu, ElMenuItem, ElIcon, ElButton, ElTag,ElTooltip,ElDialog
    },
    data(){
        return {
            localhost: true,
            localToken: "-",
            isLogin: false,
            userInfo: null,
            currentActive:"/chat",
            dialogVisible: false,
        };
    },
    watch:{

    },
    computed:{
        isVip: function(){
            if(this.userInfo == null){
                return false;
            }
            let vip = this.userInfo.vipLevel;
            if(vip && vip>0){
                return true;
            }
            return false;
        },
        docsUrl: ()=> AppConfigs.webHost + "/docs/",
        wxLoginUrl: ()=> AppConfigs.wxLoginUrl,
        chatUrl: ()=> AppConfigs.webHost + "/chat",

    },
    methods:{
        resUri(uri){
            return AppConfigs.resHost + uri;
        },
        redirectToLink(url){
            window.location.href = url;
        },
        openExternalLink(url){
            window.open(url);
        },
        handleSelect(key, keyPath){
            console.debug(key, keyPath)
        },
        messageTip(options){
            let defaultOptions = {
                duration: 1500,
            }
            ElMessage({
                ...defaultOptions,
                ...options
            })
        },
        // eslint-disable-next-line no-unused-vars
        onClickExitBtn(_){
            let _this = this;
            ElMessageBox.confirm(
                '确认退出黑石AI体验馆吗?',
                '退出提示框',
                {
                    confirmButtonText: '确认',
                    cancelButtonText: '取消',
                    type: 'warning',
                }
            )
                .then(() => {
                    ReposAuth.logout();
                    _this.redirectToLink(_this.chatUrl)
                })
        },


        _afterLogined(_this, auto){
            _this.userInfo = ReposAuth.userinfo;
            _this.isLogin = true;
            ReposSession.init(_this.userInfo).then(()=>{
                router.replace(routerPages.ChatPage)
                _this.currentActive = routerPages.ChatPage;
            }).then(()=>{
                _this.messageTip({
                    message: auto ? "自动检测登录成功" :"登录成功",
                    type: 'success',
                })
            })
        },

        registerRouter(){
            let _this = this;
            router.beforeEach((to, from, next)=>{
                if(to.path == routerPages.HomePage || to.path == routerPages.Root){
                    next();
                    _this.currentActive = to.fullPath;
                    return;
                }
                if(_this.isLogin){
                    next();
                    _this.currentActive = to.fullPath;
                }else{
                    _this.messageTip({
                        message: "本页面需要先登录",
                        type: 'warning',
                    })
                    next(routerPages.HomePage);
                }
                console.log(`current route info: \nto:`, to, "\nfrom:", from);
            });
        },

        loginChecked(){
            let _this = this;

            ReposAuth.isLogined().then(()=>{
                //已登录
                _this._afterLogined(_this, true);
            }).catch(()=>{
                let params = window.location.search;
                _this.isLogin = false;

                if(params && params.charAt(0) == "?"){
                    params = params.substring(1);
                    let pattern = /(^|&)code=([^&]*)/; // 匹配 code 参数的正则表达式
                    let code = params.match(pattern)[2]; // 获取匹配结果中的第二个分组（即 code 参数的值）
                    console.log("get code", code);

                    ReposAuth.login(code).then(()=>{
                        return ReposAuth.getUserInfoAsync()
                    }).then(()=>{
                        _this._afterLogined(_this, false);
                    }).catch(()=>{
                        /*ElMessageBox.alert('登录失败：错误信息', '登录失败提示', {
                            confirmButtonText: '我知道了',
                        })*/
                        _this.dialogVisible = true;
                    });
                    return;
                }

                if(window.location.hostname == "localhost" && _this.localhost){
                    ReposAuth.setLoginToken(this.localToken)
                    ReposAuth.getUserInfoAsync().then(()=>{
                        _this._afterLogined(_this, true);
                    }).catch(()=>{
                        _this.messageTip({
                            message: "自动检测登录失败！可能登录已过期，请重新登录！",
                            type: 'warning',
                        })
                    });
                    return;
                }

                return;
            })

        },

        addToBookmarks() {
            if (window.sidebar && window.sidebar.addPanel) { // Firefox
                window.sidebar.addPanel(document.title, window.location.href, '');
            } else if (window.external && 'AddFavorite' in window.external) { // Internet Explorer
                window.external.AddFavorite(window.location.href, document.title);
            } else {
                this.messageTip({
                    message:'抱歉，您的浏览器不支持自动添加收藏，请按 Ctrl+D (或 Cmd+D) 手动添加到书签栏。',
                    type:"warning",
                    duration: 3000,
                });
            }
        },

    },
    created() {
        this.registerRouter();
        this.loginChecked();
    }
}