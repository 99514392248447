<template>
    <div :class="'ChatSessionCard' + (active?' ChatSessionCard-active':'')" @click="onClickCard">
      <div class="card-left">
        <el-icon size="18px" style="margin-top: 2px; margin-right: 5px;"><ChatLineSquare /></el-icon>
      </div>
      <div v-if="mode=='normal'" class="card-center" >{{sessionObj.name}}</div>
      <div v-if="mode=='edit'" class="card-center" >
        <el-input v-model="inputName" :placeholder="sessionObj.name" @keydown.enter = "onInputKeyDown"/>
      </div>
      <div class="card-right">
        <el-tooltip
            class="box-item"
            effect="dark"
            content="会话重命名"
            placement="top-start"
        >
          <el-icon v-if="mode=='normal'" class="ChatSessionCardIcon"  size="18px" @click="onClickEditSessionName"><Edit /></el-icon>
          <el-icon v-if="mode=='edit'" class="ChatSessionCardIcon"  size="18px" @click="onClickConfirmEdit"><Select /></el-icon>
        </el-tooltip>
        <el-tooltip
            class="box-item"
            effect="dark"
            content="删除本会话（不可恢复）"
            placement="top-start"
        >
          <el-icon class="ChatSessionCardIcon"  size="18px" @click="onClickDeleteSession"><Delete /></el-icon>
        </el-tooltip>
      </div>

    </div>
</template>

<script src="./_ChatSessionCard.js" lang="js"></script>
<style src="./_ChatSessionCard.less" lang="less" scoped></style>
