// @vue/component
export default {
  name: 'JoinUsPage',

  components: {},

  mixins: [],

  props: {},

  data () {
    return {}
  },

  computed: {},

  watch: {},

  created () {},

  methods: {}
}
