<template>
  <el-container class="app-container">
    <el-aside width="300px" class="el-hidden-sm-only app-aside">
      <ChatSessionList></ChatSessionList>
    </el-aside>
    <div class="app-chat-container">
      <div class="app-chat-float-tools">
        <ChatFloatTools></ChatFloatTools>
      </div>
      <div class="app-chat-help-container">
        <ChatHelper></ChatHelper>
      </div>
      <div ref="scrollChatContent" class="app-main">
        <ChatSessionMessageList></ChatSessionMessageList>
      </div>
      <div class="app-footer">
        <ChatInputArea></ChatInputArea>
      </div>
    </div>
  </el-container>
</template>
<script src="./_ChatPage.js"></script>
<style src="./_ChatPage.less" lang="less" scoped></style>
